import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import LogIn from './pages/log-in/log-in';
import AddNewPatient from './pages/patients/add-new-patient/add-new-patient';
import ViewAllPatients from './pages/patients/view-all-patients/view-all-patients';
import Orders from "./pages/orders/orders";
import Admin from "./pages/admin/admin";
import Dashboard from './pages/dashboard/dashboard';
import Inventory from "./pages/inventory/inventory";
import CreateDmeUser from './pages/admin/createDmeUser/createDmeUser'
import Reports from './pages/reports/reports'
import PatientPage from './pages/patients/pacientPage/patientPage'
import MapContainer from "./pages/patients/view-all-patients/mapByAddress/mapComponent";
import './App.css';
import CreateHospiceUser from "./pages/admin/createHospiceUser/createHospiceUser";
import DeliveryOrder from "./pages/orders/deliveryOrder/deliveryOrder";
import ProfilePage from "./pages/profilePage/profilePage";
import PickupOrder from "./pages/orders/pickupOrder/pickupOrder";
import InactivityTimer from "./common/inactivityTimer/inactivityTimerWithHooks";
import AddNewItem from "./pages/inventory/addNewItem/AddNewItem";
import InventoryEditExistingItem from "./pages/inventory/editExistingItem/inventoryEditExistingItem";
import {LogOxygenTanks} from "./pages/inventory/loggingComponent/LogOxygenTanks";
import {ActiveRental} from "./pages/reports/activeRental/activeRental";
import {FacilityReport} from "./pages/reports/facility/facility";
import {BillingReport} from "./pages/reports/billing/billing";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {NavigationWrapper} from "./common/navigationWrapper/navigationWrapper";
import {Support} from "./pages/utils/support/Support";
import {checkPermissions} from "./common/permissions/checkPermissions";
import {permissions} from "./constants/permissions/permissions";
import DmeUsers from "./pages/admin/adminDashboard/dmeUsers/dmeUsers";
import HospiceUsers from "./pages/admin/adminDashboard/hospiceUsers/hospiceUsers";
import DmeTab from "./pages/admin/adminDashboard/dmeTab/dmeTab";
import HospiceTab from "./pages/admin/adminDashboard/hospiceTab/hospiceTab";
import CreateContract from "./pages/admin/adminDashboard/createContract/createContract";
import {AuditTrail} from "./pages/utils/auditTrail/AuditTrail";
import InventoryAdmin from "./pages/admin/adminDashboard/inventoryAdmin/inventoryAdmin";
import {EditDomains} from "./pages/utils/domains/EditDomains";
import {EditManufacturers} from "./pages/utils/manufacturers/EditManufacturers";
import {EditNurses} from "./pages/utils/nurses/EditNurses";
import ViewContracts from "./pages/admin/adminDashboard/viewContract/viewContracts";
import {ForgotCredentials} from "./pages/utils/forgotCredentials/ForgotCredentials";
import jwt from 'jwt-decode';
import {useDispatch, useSelector} from 'react-redux';
import ExchangeOrder from "./pages/orders/exchangeOrder/exchangeOrder";
import TechnicianOrderView from "./pages/orders/technicianOrders/technicianOrderView";
import {SnackbarProvider} from "notistack";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {UserProfile} from "./pages/profilePage/UserProfile";
import {BulkUpload} from "./pages/admin/adminDashboard/bulkUpload/bulkUpload";
import {EditTechnicians} from "./pages/utils/technicians/EditTechnicians";
import {DmeResearch} from "./pages/admin/adminDashboard/dmeResearch/dmeResearch";
import {FeatureEnabler} from "./pages/utils/featureEnabler/FeatureEnabler";
import HospicePortalUser from "./pages/admin/createPortalUser/HospicePortalUser";
import PortalUsers from "./pages/admin/adminDashboard/portalUsers/PortalUsers";
import {CreateDmeProviderIndex} from "./pages/admin/createDmeProvider/DmeProviderIndex";
import ExportInventory from "./pages/inventory/exportInventory/exportInventory";
import PrintInventory from './pages/inventory/printInventory/printInventory';
import AddInventory from './pages/inventory/printInventory/addInventory';
import {HospiceProviderIndex} from "./pages/admin/createHospiceProvider/HospiceProviderIndex";
import PaceHospices from "./pages/admin/adminDashboard/paceHospices/paceHospices";
import CreateSurvey from "./pages/admin/adminDashboard/createSurvey/createSurvey";
import {SurveyResponses} from "./pages/admin/surveyTool/SurveyResponse";
import AllSurveys from "./pages/admin/adminDashboard/allSurveys/allSurveys";
import Engagements from "./pages/engagements/engagements";
import Requests from './pages/requests/Requests';
import {NormalRefresh, Refresh} from "./common/refresh/refresh";
import {logout} from "./redux/actions";
import HospiceTypes from "./pages/admin/adminDashboard/hospiceTypes/paceHospices";
import ApproveOrders from "./pages/orders/approveOrders/approveOrders";
import CreateSystemNotifications from "./pages/admin/systemNotifications/createSystemNotifications/createSystemNotifications";
import SendSystemNotifications from "./pages/admin/systemNotifications/sendSystemNotifications/sendSystemNotifications";
import TicTacToe from "./pages/admin/adminDashboard/easterEgg/ticTacToe";
import DigitalSignature from "./pages/admin/adminDashboard/digitalSignature";
import {HospiceLogoIndex} from "./pages/admin/adminDashboard/hospiceLogo/HospiceLogoIndex";
import {CreateCustomHospicePackages} from "./pages/admin/adminDashboard/customHospicePackages/createPackage/createCustomHospicePackage";
import {ViewCustomPackages} from "./pages/admin/adminDashboard/customHospicePackages/viewCustomPackages/viewCustomPackages";
import TriggerSurvey from "./pages/admin/adminDashboard/triggerSurvey/triggerSurvey";
import {LostItemsReport} from "./pages/reports/lostItems/lostItems";
import {BusinessReview} from "./pages/reports/businessReview/BusinessReview";
import {BulkMedicalRecordUpload} from "./pages/admin/adminDashboard/bulkUpload/medicalRecords/BulkMedicalRecordUpload";
import AddItemDocuments from "./pages/inventory/addItemDocuments/addItemDocuments";
import {OOFLFMonthly} from "./pages/reports/oofReport/oofLfMonthly";
import {ProtectedRoute} from "./common/protectedRoute/protectedRoute";
import UnauthorizedRoute from "./common/unauthorizedRoute/unauthorizedRoute";
import UnauthorizedAccess from "./pages/utils/auditTrail/UnauthorizedAccess";
import CleaningAndMaintenance from "./pages/inventory/cleaningAndMaintenance/cleaningAndMaintenance";
import {PermissionPackages} from "./pages/admin/adminDashboard/permissionPackages/PermissionPackages";
import {BulkUserUpload} from "./pages/admin/adminDashboard/bulkUpload/BulkUserUpload";
import {endpoints} from "./constants/endpoints";
import {Dialog, DialogActions, DialogContent, DialogTitle, Slide} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {checkTokenAvailability} from "./common/utils/utils";
import UsersReport from "./pages/reports/usersReport/usersReport";
import UsersCustomAuditReport from "./pages/reports/usersReport/usersCustomAuditReport";
import RouteNotFound from './common/routeNotFound/RouteNotFound'
import PaceReport from "./pages/reports/paceRoports/paceReport";
import {NurseOrdering} from "./pages/reports/nuserOrdering/NurseOrdering";
import {SpecificActiveRental} from "./pages/reports/activeRental/SpecificActiveRental";
import {QapiDashboard} from "./pages/qapi/QapiDashboard";
import {InventoryDashboard} from "./pages/inventory/InventoryDashboard";
import {IncidentServiceForm} from "./pages/utils/support/components/IncidentServiceForm";
import useOnlineStatus from "./common/hooks/useOnlineStatus";
import CloudOffIcon from '@material-ui/icons/CloudOff';
import Grid from "@material-ui/core/Grid";
import {TechnicianActivity} from "./pages/reports/technicianActivity/TechnicianActivity";
import {LogisticsDashboard} from "./pages/logistics/LogisticsDashboard";
import {GeographicReport} from "./pages/reports/geographicReport/GeographicReport";
import LoginWithTempPass from "./pages/log-in/log-in-tempPass";
import {RedirectToLoginWithOriginalPath} from "./common/redirect/RedirectToLoginWithOriginalPath";
import {ActiveRentalSummary} from "./pages/reports/activeRental/ActiveRentalSummary";
import ViewIncidentLogs from "./pages/qapi/viewIncidentLogs";
import {DiseasesReport} from "./pages/reports/diseasesReport/DiseasesReport";
import {LengthOfStayReport} from "./pages/reports/patients/LengthOfStayReport";

const useStyles = makeStyles({
    success: {backgroundColor: '#117C70 !important', fontWeight: 400},
    error: {backgroundColor: '#CD1D32 !important', fontWeight: 400},
    warning: {backgroundColor: '#CEC129 !important', fontWeight: 400},
    information: {backgroundColor: '#7B99B5 !important', fontWeight: 400},
});

function Routes() {
    const online = useOnlineStatus();
    const history = useHistory();
    const dispatch = useDispatch();
    const [hasToken, setHasToken] = useState(false);
    const [number, setNumber] = useState(0);
    const [showNavBar, setShowNavBar] = useState(true);
    const [loginToken, setLoginToken] = useState('');
    const [loginTempPass, setLoginTempPass] = useState('');
    const [outdatedVersion,setOutdatedVersion] = useState(false);
    const [handleOfflineBox, setHandleOfflineBox] = useState(false);

        
    useEffect(() => {
        setHandleOfflineBox(!online);
        if(online && handleOfflineBox){
            history.push('/refresh')
        }
    }, [online]);



    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    let token = localStorage.getItem('key');
    const classes = useStyles();
    let username;
    if (JSON.parse(token) !== null) {
        token = JSON.parse(token);
        let user = jwt(token.accessToken);
        username = user.payload.username;
    }
    const isLogged = useSelector(state => state.isLogged);
    const checkUser = () => {
        if (username) {
            setHasToken(true);
        }
        setNumber(number + 1);
    };

    useEffect(() => {
        if (isLogged && !hasToken) {
            checkUser();
        } else if (!isLogged) {
            setHasToken(false);
        }
    }, [number, isLogged]);

    useEffect(() => {
        if(!outdatedVersion && isLogged && hasToken){
            const interval = setInterval(async () => {
                    const availableToken = await checkTokenAvailability();
                    if (!availableToken) {
                        dispatch(logout());
                        history.push('/login');
                    }
                const token = JSON.parse(localStorage.getItem('key'));
                    if (token){
                        fetch(`${endpoints.version}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token.accessToken
                            },
                            body: JSON.stringify({version: process.env.REACT_APP_VERSION})
                        })
                            .then(response => {
                                return response.json()
                            })
                            .then(response => {
                                if(response.refresh === true){
                                    setOutdatedVersion(true);
                                }
                            })
                            .catch(err => console.log('error', err))
                    }
            }, 300000); //5 minutes = 1000 * 60 * 5
            return () => clearInterval(interval);
        }
    },[outdatedVersion,isLogged,hasToken])

    const handleClose = () => {
        setOutdatedVersion(false);
    }

    const handleUpdate = () => {
        setOutdatedVersion(false);
        localStorage.setItem('key', JSON.stringify(null));
        dispatch(logout());
        window.location.reload();
    }

    // useEffect(() => {
    //     if (JSON.parse(token) !== null) {
    //         (async () => {
    //             const availableToken = await checkTokenAvailability();
    //             if (!availableToken) {
    //                 dispatch(logout());
    //                 history.push('/login');
    //             }
    //         })();
    //     }
    // },[history])
    //dark theme provider
    //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    // const prefersDarkMode = true;
    // const theme = React.useMemo(
    //     () =>
    //         createMuiTheme({
    //           palette: {
    //             type: prefersDarkMode ? 'dark' : 'light',
    //           },
    //         }),
    //     [prefersDarkMode],
    // );
    const theme = createMuiTheme({
        // breakpoints: {
        //     values: {
        //         lg:960,
        //         xl: 1400,
        //     },
        // },
        palette: {
            primary: {
                main: '#00223D',
            },
        },
        typography: {
            fontFamily: [
                'Nunito'
            ].join(','),
            h1: {
                fontSize: '48px',
                lineHeight: '52px',
                color: '#404040',
                fontWeight: 600,
                marginBottom: '64px'
            },
            button: {
                fontSize: '16px',
                textTransform: 'none',
            }
        },
        overrides: {
            MuiDialog:{
                paperWidthXs:{
                    maxWidth:320
                }
            },
            MuiButton: {
                root: {
                    // padding:100,
                },
                // primary: {
                //     color: 'white',
                // },
                containedPrimary: {
                    height: 40,
                    color: '#FFF',
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: '600',
                    backgroundColor: "#00223D",
                    '&:hover': {
                        backgroundColor: '#004175',
                        color: '#FFF'
                    }
                },
            },
            MuiInput: {
                focused: {},
                underline: {
                    '&:after': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&:before': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&:focused::after': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&:hover:not($disabled):before': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&$disabled:before': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                },
            },
            MuiInputBase: {
                focused: {},
                root: {
                    '&:after': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&:before': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&:focused::after': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&:hover:not($disabled):before': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                    '&$disabled:before': {
                        borderBottom: '1px solid #A3C7D2'
                    },
                },

            },
            MuiDrawer: {
                paperAnchorDockedLeft: {
                    // border:'none !important'
                }
            }
        }
    });
    useEffect(()=> {
        let token = localStorage.getItem('key');
        if ( (token === null) || (JSON.parse(token) === null)){
            dispatch(logout());
        }
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.information,
                }}
            >
                <div className="App">
                    <NavigationWrapper showNavBar={showNavBar}>
                        <Switch>
                            <Route
                                path='/login'
                                render={(props) => <LogIn {...props} setShowNav={setShowNavBar} loginToken={loginToken} setLoginToken={setLoginToken}/>}
                            />
                            <Route exact path='/unauthorized' component={UnauthorizedRoute}/>
                            <Route exact path='/dashboard' component={Dashboard}/>
                            <Route exact path='/support' component={Support}/>
                            <Route exact path='/order/reject/:orderId/:orderType/:token'
                                   render={(props) => <ApproveOrders {...props}/>}/>
                            <Route exact path='/order/approve/:orderId/:orderType/:token'
                                   render={(props) => <ApproveOrders {...props}/>}/>
                            <Route exact path='/reset/pass/login'
                                   render={(props) => <LoginWithTempPass {...props} setShowNav={setShowNavBar}/>}/>
                            <Route exact path='/forgotCredentials'
                                   render={(props) => <ForgotCredentials {...props} setShowNav={setShowNavBar}/>}/>
                            <Route path='/showMap' component={MapContainer}/>
                            <Route exact path='/404' component={RouteNotFound}/>
                            <Route exact path='/order/technician/:technicianId/:token/:orderType/:orderId' component={TechnicianOrderView}/>
                            <Route exact path='/redirect/order/:orderType/:orderId' component={RedirectToLoginWithOriginalPath}/>
                            <Route exact path='/redirect/vieworders' component={RedirectToLoginWithOriginalPath}/>

                            {/*following routes are available only if user its logged*/}

                                    <Refresh path="/refresh"/>
                                    <NormalRefresh path="/regular/refresh"/>
                                    <Route exact path='/profile/edit' component={ProfilePage}/>
                                    <Route exact path='/user/info' component={UserProfile}/>
                                    <Route exact path='/support/incident' component={IncidentServiceForm}/>

                            {/*//Patient routes*/}
                                    <ProtectedRoute exact path='/patients/new' permission={checkPermissions([permissions.create_patients])} component={AddNewPatient}/>
                                    <ProtectedRoute exact path='/patients/edit' permission={checkPermissions([permissions.update_patients])} component={AddNewPatient}/>
                                    <ProtectedRoute exact path='/patients/all' permission={checkPermissions([permissions.read_patients])} component={ViewAllPatients}/>
                                    <ProtectedRoute exact path='/patients/patientProfile/:medicalRecord' permission={checkPermissions([permissions.read_patients])} component={PatientPage}/>
                                    <ProtectedRoute exact path='/portal/profile' permission={checkPermissions([permissions.portal_view_profile])} render={(props) => <PatientPage {...props} portal={true} setShowNavBar={setShowNavBar}/>} />

                                    {/*Orders Routes*/}
                                    <ProtectedRoute exact path='/order/delivery/:id' permission={checkPermissions([permissions.read_orders])} component={DeliveryOrder}/>
                                    <ProtectedRoute exact path='/order/pickup/:id' permission={checkPermissions([permissions.read_orders])} component={PickupOrder}/>
                                    <ProtectedRoute exact path='/order/exchange/:id' permission={checkPermissions([permissions.read_orders])} component={ExchangeOrder}/>
                                    <ProtectedRoute exact path='/orders' permission={checkPermissions([permissions.read_orders])} component={Orders}/>

                                    {/*Inventory Routes*/}
                                    <ProtectedRoute exact path='/inventory/new' permission={checkPermissions([permissions.create_inventory])} component={AddNewItem}/>
                                    <ProtectedRoute exact path='/inventory/edit' permission={checkPermissions([permissions.create_inventory])} component={InventoryEditExistingItem}/>
                                    <ProtectedRoute exact path='/inventory/lots' permission={checkPermissions([permissions.create_inventory])} component={LogOxygenTanks}/>
                                    <ProtectedRoute exact path='/inventory/export' permission={checkPermissions([permissions.read_inventory])} component={ExportInventory}/>
                                    <ProtectedRoute exact path='/inventory/print' permission={checkPermissions([permissions.read_inventory])} component={PrintInventory}/>
                                    <ProtectedRoute exact path='/inventory/add' permission={checkPermissions([permissions.read_inventory])} component={AddInventory}/>
                                    <ProtectedRoute exact path='/inventory/cleaning/maintenance' permission={checkPermissions([permissions.create_inventory])} component={CleaningAndMaintenance}/>
                                    <ProtectedRoute exact path='/inventory/view' permission={checkPermissions([permissions.read_inventory])} component={Inventory}/>
                                    <ProtectedRoute exact path='/inventory' permission={checkPermissions([permissions.read_inventory])} component={InventoryDashboard}/>

                                    {/*Qapi Routes*/}
                                    <ProtectedRoute exact path='/qapi' permission={checkPermissions([permissions.read_admin])} component={QapiDashboard}/>
                                    <ProtectedRoute exact path='/incident/logs' permission={checkPermissions([permissions.read_admin])} component={ViewIncidentLogs}/>

                                    {/*Logistics Routes*/}
                                    <ProtectedRoute exact path='/logistics' permission={checkPermissions([permissions.read_admin])} component={LogisticsDashboard}/> // TODO:add-qapi-report-permission

                                    {/*Reports Routes*/}
                                    <ProtectedRoute exact path='/reports/create/billingreport' permission={checkPermissions([permissions.billing_report])} component={BillingReport}/>
                                    <ProtectedRoute exact path='/reports/create/dieseaseReport' permission={checkPermissions([permissions.billing_report])} component={DiseasesReport}/>
                                    <ProtectedRoute exact path='/reports/create/activerental/full' permission={checkPermissions([permissions.active_rental_report])} component={ActiveRental}/>
                                    <ProtectedRoute exact path='/reports/create/activerental/inactivePatients' permission={checkPermissions([permissions.active_rental_report])} render={(props) => <ActiveRental {...props} inactivePatientsOption={true}/>}/>
                                    <ProtectedRoute exact path='/reports/create/activerental/specific' permission={checkPermissions([permissions.active_rental_report])} component={SpecificActiveRental}/>
                                    <ProtectedRoute exact path='/reports/create/activerental/summary' permission={checkPermissions([permissions.active_rental_report])} component={ActiveRentalSummary}/>
                                    <ProtectedRoute exact path='/reports/create/facilityReport' permission={checkPermissions([permissions.reports_facility])} component={FacilityReport}/>
                                    <ProtectedRoute exact path='/reports/create/oofreport' permission={checkPermissions([permissions.oof_report])} component={BillingReport}/>
                                    <ProtectedRoute exact path='/reports/create/businessReview' permission={checkPermissions([permissions.reports_business_review])} component={BusinessReview}/>
                                    <ProtectedRoute exact path='/reports/create/geographicReport' permission={checkPermissions([permissions.read_admin])} component={GeographicReport}/>
                                    <ProtectedRoute exact path='/reports/create/lengthOfStay' permission={checkPermissions([permissions.read_admin])} component={LengthOfStayReport}/>
                                    <ProtectedRoute exact path='/reports/create/lostitems' permission={checkPermissions([permissions.reports_lost_item])} component={LostItemsReport}/>
                                    <ProtectedRoute exact path='/reports/create/pacereport' permission={checkPermissions([permissions.reports_pace])} component={PaceReport}/>
                                    <ProtectedRoute exact path='/reports/create/users/dme' permission={checkPermissions([permissions.reports_users], "DME")} component={UsersReport}/>
                                    <ProtectedRoute exact path='/reports/create/users/hospice' permission={checkPermissions([permissions.reports_users])} component={UsersReport}/>

                                    <ProtectedRoute exact path='/reports/custom/audit/hospice' permission={checkPermissions([permissions.reports_users])} component={UsersCustomAuditReport}/>
                                    <ProtectedRoute exact path='/reports/custom/audit/dme' permission={checkPermissions([permissions.reports_users])} component={UsersCustomAuditReport}/>

                                    <ProtectedRoute exact path='/reports/create/users/nurseorderreport' permission={checkPermissions([permissions.reports_order_placement])} component={NurseOrdering}/>
                                    <ProtectedRoute exact path='/reports/technician/activity' permission={checkPermissions([permissions.technician_activity])} component={TechnicianActivity}/>
                                    <ProtectedRoute exact path='/reports'
                                                    permission={checkPermissions([permissions.billing_report]) ||
                                                                checkPermissions([permissions.active_rental_report]) ||
                                                                checkPermissions([permissions.oof_report]) ||
                                                                checkPermissions([permissions.reports_lost_item]) ||
                                                                checkPermissions([permissions.reports_facility]) ||
                                                                checkPermissions([permissions.reports_business_review]) ||
                                                                checkPermissions([permissions.reports_pace]) ||
                                                                checkPermissions([permissions.reports_users]) ||
                                                                checkPermissions([permissions.reports_order_placement]) ||
                                                                checkPermissions([permissions.technician_activity])
                                                    }
                                                    component={Reports}
                                    />
                                    <ProtectedRoute exact path='/admin/portaluser'
                                                    permission={true}
                                                    component={HospicePortalUser}/>
                                    <ProtectedRoute exact path='/admin/dmeresearch' permission={checkPermissions([permissions.read_admin], "DME")} component={DmeResearch}/>
                                    <ProtectedRoute exact path='/admin/dmeusers/all' permission={checkPermissions([permissions.read_admin], "DME")} component={DmeUsers}/>
                                    <ProtectedRoute exact path='/admin/hospiceusers/all' permission={checkPermissions([permissions.read_admin], "DME")} component={HospiceUsers}/>
                                    <ProtectedRoute exact path='/admin/portalusers/all' permission={checkPermissions([permissions.read_admin], "DME")} component={PortalUsers}/>
                                    <ProtectedRoute exact path='/admin/dme/all' permission={checkPermissions([permissions.read_admin], "DME")} component={DmeTab}/>
                                    <ProtectedRoute exact path='/admin/hospice/all' permission={checkPermissions([permissions.read_admin], "DME")} component={HospiceTab}/>
                                    <ProtectedRoute exact path='/admin/pacehospice/all' permission={checkPermissions([permissions.read_admin], "DME")} component={PaceHospices}/>
                                    <ProtectedRoute exact path='/admin/hospice/type' permission={checkPermissions([permissions.read_admin], "DME")} component={HospiceTypes}/>
                                    <ProtectedRoute exact path='/admin/hospice/logo' permission={checkPermissions([permissions.read_admin], "DME")} component={HospiceLogoIndex}/>
                                    <ProtectedRoute exact path='/admin/hospice/custompackages/create' permission={checkPermissions([permissions.create_admin], "DME")} component={CreateCustomHospicePackages}/>
                                    <ProtectedRoute exact path='/admin/hospice/custompackages/view' permission={checkPermissions([permissions.read_admin], "DME")} component={ViewCustomPackages}/>
                                    <ProtectedRoute exact path='/admin/contract/create' permission={checkPermissions([permissions.create_admin], "DME")} component={CreateContract}/>
                                    <ProtectedRoute exact path='/admin/survey/create' permission={checkPermissions([permissions.create_admin], "DME")} component={CreateSurvey}/>
                                    <ProtectedRoute exact path='/admin/survey/send' permission={checkPermissions([permissions.create_admin], "DME")} component={TriggerSurvey}/>
                                    <ProtectedRoute exact path='/admin/survey/edit' permission={checkPermissions([permissions.create_admin], "DME")} component={CreateSurvey}/>
                                    <ProtectedRoute exact path='/admin/item/documents' permission={checkPermissions([permissions.create_admin], "DME")} component={AddItemDocuments}/>
                                    <ProtectedRoute exact path='/requests' permission={checkPermissions([permissions.request_create]) || checkPermissions([permissions.request_deactivate])} component={Requests}/>
                                    <ProtectedRoute exact path='/engagements' permission={checkPermissions([permissions.create_admin], "DME")} component={Engagements}/>
                                    <ProtectedRoute exact path='/admin/system/notifications' permission={checkPermissions([permissions.create_admin], "DME")} component={CreateSystemNotifications}/>
                                    <ProtectedRoute exact path='/admin/system/notifications/send' permission={checkPermissions([permissions.create_admin], "DME")} component={SendSystemNotifications}/>
                                    <ProtectedRoute exact path='/admin/system/tictactoe' permission={checkPermissions([permissions.read_admin], "DME")} component={TicTacToe}/>
                                    <ProtectedRoute exact path='/admin/system/signature' permission={checkPermissions([permissions.read_admin], "DME")} component={DigitalSignature}/>
                                    <ProtectedRoute exact path='/admin/survey/all' permission={checkPermissions([permissions.read_admin], "DME")} component={AllSurveys}/>
                                    <ProtectedRoute exact path='/admin/contract/edit/:id' permission={checkPermissions([permissions.create_admin], "DME")} render={(props) => <CreateContract {...props} edit={true}/>} />
                                    <ProtectedRoute exact path='/admin/contract/all' permission={checkPermissions([permissions.read_admin], "DME")} component={ViewContracts}/>
                                    <ProtectedRoute exact path='/admin/audittrail' permission={checkPermissions([permissions.read_admin], "DME")} component={AuditTrail}/>
                                    <ProtectedRoute exact path='/admin/audittrail/portal' permission={checkPermissions([permissions.read_admin], "DME")} render={(props) => <AuditTrail {...props} portal={true}/>}/>
                                    <ProtectedRoute exact path='/admin/masterinventory' permission={checkPermissions([permissions.read_admin], "DME")} component={InventoryAdmin}/>
                                    <ProtectedRoute exact path='/admin/restricteddomains' permission={checkPermissions([permissions.read_admin], "DME")} component={EditDomains}/>
                                    <ProtectedRoute exact path='/admin/manufacturers' permission={checkPermissions([permissions.read_admin], "DME")} component={EditManufacturers}/>
                                    <ProtectedRoute exact path='/admin/technicians' permission={checkPermissions([permissions.read_admin], "DME")} component={EditTechnicians}/>
                                    <ProtectedRoute exact path='/admin/nurses' permission={checkPermissions([permissions.read_admin], "DME")} component={EditNurses}/>
                                    <ProtectedRoute exact path='/dmeprovider/new' permission={checkPermissions([permissions.read_admin], "DME")} component={CreateDmeProviderIndex}/>
                                    <ProtectedRoute exact path='/dmeprovider/edit/:id' permission={checkPermissions([permissions.read_admin], "DME")}  render={(props) => <CreateDmeProviderIndex {...props} edit={true}/>} />
                                    <ProtectedRoute exact path='/dmeuser/new' permission={checkPermissions([permissions.read_admin], "DME")} component={CreateDmeUser}/>
                                    <ProtectedRoute exact path='/admin/permissionPackages' permission={checkPermissions([permissions.read_admin], "DME")} component={PermissionPackages}/>
                                    <ProtectedRoute exact path='/hospiceuser/new' permission={checkPermissions([permissions.read_admin], "DME")} component={CreateHospiceUser}/>
                                    <ProtectedRoute exact path='/hospiceuser/edit/:id' permission={checkPermissions([permissions.read_admin], "DME")} render={(props) => <CreateHospiceUser {...props} edit={true}/>} />
                                    <ProtectedRoute exact path='/hospice/new' permission={checkPermissions([permissions.read_admin], "DME")} component={HospiceProviderIndex}/>
                                    <ProtectedRoute exact path='/hospice/edit/:id' permission={checkPermissions([permissions.read_admin], "DME")}  render={(props) => <HospiceProviderIndex {...props} edit={true}/>} />
                                    <ProtectedRoute exact path='/dmeuser/edit/:id' permission={checkPermissions([permissions.read_admin], "DME")}  render={(props) => <CreateDmeUser {...props} edit={true}/>} />
                                    <ProtectedRoute exact path='/admin/bulk/medicalrecords' permission={checkPermissions([permissions.read_admin], "DME")} component={BulkMedicalRecordUpload}/>
                                    <ProtectedRoute exact path='/admin/bulk/users' permission={checkPermissions([permissions.read_admin], "DME")} component={BulkUserUpload}/>
                                    <ProtectedRoute exact path='/admin/bulk' permission={checkPermissions([permissions.read_admin], "DME")} component={BulkUpload}/>
                                    <ProtectedRoute exact path='/admin/enablefeatures' permission={checkPermissions([permissions.read_admin], "DME")} component={FeatureEnabler}/>
                                    <ProtectedRoute exact path='/admin/surveyresponse' permission={checkPermissions([permissions.read_admin], "DME")} component={SurveyResponses}/>
                                    <ProtectedRoute exact path='/admin/audit/unauthorized' permission={checkPermissions([permissions.read_admin], "DME")} component={UnauthorizedAccess}/>
                                    <ProtectedRoute exact path='/admin' permission={checkPermissions([permissions.read_admin], "DME")} component={Admin}/>

                            <Route exact path='/' component={Dashboard}/>
                            <Redirect from='*' to='/404'/>
                        </Switch>
                    </NavigationWrapper>
                </div>
            </SnackbarProvider>

            <InactivityTimer/>

            {outdatedVersion && (
                <Dialog onClose={handleClose} TransitionComponent={Transition} aria-labelledby="simple-dialog-title" open={outdatedVersion}>
                    <DialogTitle style={{fontWeight: 700}}>New application version available</DialogTitle>
                    <DialogContent>
                        <p>New version now available</p>
                        <p>In order to use the application at it's full potential we recommend you to update it</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{backgroundColor:'#ededed', padding: '5px 20px'}}>Snooze</Button>
                        <Button onClick={handleUpdate} style={{backgroundColor: '#4F86A0',color:'#ffffff',fontWeight: 700,padding: '5px 20px'}}>Update</Button>
                    </DialogActions>
                </Dialog>
            )};
                {handleOfflineBox && (
                <Dialog TransitionComponent={Transition} aria-labelledby="simple-dialog-title" open={!online}>
                    <DialogContent>
                        <Grid container justify={'center'} alignContent={'center'} alignItems={'center'} style={{minHeight:320}}>
                            <Grid container justify={'center'}>
                                <CloudOffIcon style={{fontSize:80, color:'#00223D'}} />
                            </Grid>
                            <Grid container justify={'center'}>
                                <h1 style={{color:'#00223D'}}>You are offline</h1>
                            </Grid>
                            <Grid container justify={'center'}>
                                <p style={{fontSize:18, color:"gray", textAlign:'center'}}>In order to use the application please connect to internet</p>
                            </Grid>
                        </Grid>

                    </DialogContent>
                </Dialog>
                )}
        </ThemeProvider>
    );
}

export default Routes;
