import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { utilsTokenIdentifiers } from "../../../constants/inputs/values";
import { getTokenInformation } from "../../../common/utils/utils";
import { getContractedHospices, getDMEOrganizationById, getContractedItems, printContracts } from "./printInventory";
import { AlertDialog } from "../../../common/alerts/generalAlert/generalAlert";
import { buttonText } from "../../../constants/alert/alertButtonTexts";
import { alertMessages } from "../../../constants/alert/alertMessages";
import { alertTitles } from "../../../constants/alert/alertTitle";
import { buttonTypes } from "../../../constants/alert/buttonTypes";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function AddInventory() {
    const userData = getTokenInformation(history, utilsTokenIdentifiers.payload);
    const history = useHistory();
    const [DMEDetails, setDMEDetails] = useState({});
    const [contractedHospice, setContractedHospice] = useState([]);
    const [contractData, setContractData] = useState([]);
    const [items, setItems] = useState([]);
    const [name, setName] = useState([""]);
    const [warehouse, setWarehouse] = useState([""]);
    const [vehicle, setVehicle] = useState([""]);
    const [repair, setRepair] = useState([""]);
    const [input, setInput] = useState([{
        name: "",
        warehouse: "",
        vehicle: "",
        repair: "",
    }]);
    const classes = useStyles();

    useEffect(() => {
        getContractedHospices(setContractedHospice, [userData.dme_organization_id]);
        getDMEOrganizationById(userData.dme_organization_id, setDMEDetails);
    }, []);

    useEffect(() => {
        getContractedItems(userData.dme_organization_id, contractedHospice, setContractData);
    }, [contractedHospice]);

    useEffect(() => {
        getItems();
    }, [contractData]);

    const handleInput = (e) => {
        let arr = [...items];
        arr.map(item => {
            if (arr.indexOf(item) == e.id) {
                item = { ...item, [e.name]: e.value };
                arr[e.id] = item;
                setItems(arr);
            }
        });
    }

    const handleOtherItems = (e) => {
        switch (e.name) {
            case "name":
                let newName = [...name];
                newName[e.id] = e.value;
                setName([...newName]);
                break;
            case "warehouse":
                let newWareouse = [...warehouse];
                newWareouse[e.id] = e.value;
                setWarehouse(newWareouse);
                break;
            case "vehicle":
                let newVehicle = [...vehicle];
                newVehicle[e.id] = e.value;
                setVehicle(newVehicle);
                break;
            case "repair":
                let newRepair = [...repair];
                newRepair[e.id] = e.value;
                setRepair(newRepair);
                break;
        }
    }

    const handleAdd = (e) => {
        let index = Number(e.id) + 1;
        let newName = [...name];
        let newWareouse = [...warehouse];
        let newVehicle = [...vehicle];
        let newRepair = [...repair];
        newName.splice(index, 0, "");
        newWareouse.splice(index, 0, "");
        newVehicle.splice(index, 0, "");
        newRepair.splice(index, 0, "");
        setInput([...input, {
            name: "",
            warehouse: "",
            vehicle: "",
            repair: "",
        }]);
        setName(newName);
        setWarehouse(newWareouse);
        setVehicle(newVehicle);
        setRepair(newRepair);
    }

    const handleRemove = (e) => {
        let arr = [...input];
        let newName = [...name];
        let newWareouse = [...warehouse];
        let newVehicle = [...vehicle];
        let newRepair = [...repair];
        arr.length > 1 ? arr.splice(e.id, 1) : arr = [{ name: "", warehouse: "", vehicle: "", repair: "", }];
        newName.length > 1 ? newName.splice(e.id, 1) : newName = [""];
        newWareouse.length > 1 ? newWareouse.splice(e.id, 1) : newWareouse = [""];
        newVehicle.length > 1 ? newVehicle.splice(e.id, 1) : newVehicle = [""];
        newRepair.length > 1 ? newRepair.splice(e.id, 1) : newRepair = [""];
        setInput(arr);
        setName(newName);
        setWarehouse(newWareouse);
        setVehicle(newVehicle);
        setRepair(newRepair);
    }

    const handlePrint = () => {
        let arr = [...input];
        for (let i = 0; i < arr.length; i++) {
            arr[i] = {
                name: name[i],
                warehouse: warehouse[i],
                vehicle: vehicle[i],
                repair: repair[i],
            };
        }
        printContracts(userData, items, arr, DMEDetails);
        getItems();
        setInput([{
            name: "",
            warehouse: "",
            vehicle: "",
            repair: "",
        }]);
        setName([""]);
        setWarehouse([""]);
        setVehicle([""]);
        setRepair([""]);
    }

    const getItems = () => {
        let object = [];
        contractData.map(items => {
            let item = {};
            item.name = items.name;
            item.warehouse = items.warehouse !== undefined;
            item.vehicle = items.vehicle !== undefined;
            item.repair = items.repair !== undefined;
            item.type = items.type;
            object.push(item);
            
        });
        setItems(object);
    }

    return (
        <React.Fragment>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10, paddingBottom: 10}}>
                        Inventory Form
                    </Typography>
                    <Grid container spacing={1} direction="column" >
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Typography>Formulary Items</Typography>
                            </AccordionSummary>
                            <AccordionDetails>  
                                <Grid container>
                                    {items.map(item => item.type === 'PD' &&
                                        <Grid item>
                                            <TextField
                                                label="Item"
                                                variant="outlined"
                                                size="small"
                                                defaultValue={item.name}
                                                InputProps={{ readOnly: true }}
                                                margin="dense"
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Warehouse"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="warehouse"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Vehicles"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="vehicle"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Repair"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="repair"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Typography>Limited Formulary Items</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {items.map(item => item.type === 'LF' &&
                                        <Grid item>
                                            <TextField
                                                label="Item"
                                                variant="outlined"
                                                size="small"
                                                defaultValue={item.name}
                                                InputProps={{ readOnly: true }}
                                                margin="dense"
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Warehouse"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="warehouse"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Vehicles"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="vehicle"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Repair"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="repair"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Typography>Out-of-Formulary Items</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {items.map(item => item.type === 'OOF' &&
                                        <Grid item>
                                            <TextField
                                                label="Item"
                                                variant="outlined"
                                                size="small"
                                                defaultValue={item.name}
                                                InputProps={{ readOnly: true }}
                                                margin="dense"
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Warehouse"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="warehouse"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Vehicles"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="vehicle"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Repair"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="repair"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Typography>Purchase Items </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {items.map(item => item.type === 'PI' &&
                                        <Grid item>
                                            <TextField
                                                label="Item"
                                                variant="outlined"
                                                size="small"
                                                defaultValue={item.name}
                                                InputProps={{ readOnly: true }}
                                                margin="dense"
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Warehouse"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="warehouse"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Vehicles"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="vehicle"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                            <TextField
                                                label="Repair"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                id={items.indexOf(item)}
                                                name="repair"
                                                onChange={(e) => handleInput(e.target)}
                                                style={{ marginRight: 10 }} />
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Typography>Other Items</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column">
                                    {input.map(item =>
                                        <Grid container spacing={8} alignItems="center" >
                                            <Grid item >
                                                <TextField
                                                    label="Item"
                                                    variant="outlined"
                                                    size="small"
                                                    margin="dense"
                                                    id={input.indexOf(item)}
                                                    value={name[input.indexOf(item)]}
                                                    name="name"
                                                    onChange={(e) => handleOtherItems(e.target)}
                                                    style={{ marginRight: 10 }} />
                                                <TextField
                                                    label="Warehouse"
                                                    variant="outlined"
                                                    size="small"
                                                    margin="dense"
                                                    id={input.indexOf(item)}
                                                    value={warehouse[input.indexOf(item)]}
                                                    name="warehouse"
                                                    onChange={(e) => handleOtherItems(e.target)}
                                                    style={{ marginRight: 10 }} />
                                                <TextField
                                                    label="Vehicles"
                                                    variant="outlined"
                                                    size="small"
                                                    margin="dense"
                                                    id={input.indexOf(item)}
                                                    value={vehicle[input.indexOf(item)]}
                                                    name="vehicle"
                                                    onChange={(e) => handleOtherItems(e.target)}
                                                    style={{ marginRight: 10 }} />
                                                <TextField
                                                    label="Repair"
                                                    variant="outlined"
                                                    size="small"
                                                    margin="dense"
                                                    id={input.indexOf(item)}
                                                    value={repair[input.indexOf(item)]}
                                                    name="repair"
                                                    onChange={(e) => handleOtherItems(e.target)}
                                                    style={{ marginRight: 10 }} />
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Add new item" aria-label="Add new item">
                                                    <IconButton onClick={(e) => {
                                                        e.target.id = input.indexOf(item);
                                                        handleAdd(e.target);
                                                    }}>
                                                    <AddCircleIcon color="primary" aria-label="Add new item"/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Remove this item" aria-label="Remove this item">
                                                    <IconButton onClick={(e) => {
                                                        e.target.id = input.indexOf(item);
                                                        handleRemove(e.target);
                                                    }}>
                                                    <RemoveCircleIcon color="secondary" aria-label="Remove this item"/>
                                                    </IconButton>
                                                </Tooltip> 
                                            </Grid>
                                        </Grid>  
                                    )} 
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Grid container justify="center">
                                <AlertDialog
                                    title={alertTitles.warn}
                                    denyActionText={buttonText.negative}
                                    confirmActionText={buttonText.proceed}
                                    content={alertMessages.cancelConfirmation}
                                    confirmAction={() => { history.push('/inventory') }}
                                    buttonText={buttonText.camelCaseCancel}
                                    type={buttonTypes.standard}
                                    disabledButton={false}
                                />
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    className={classes.button}
                                    onClick={handlePrint}
                                >Print
                                </Button>
                        </Grid>
                        
                    </Grid>
                </Paper>
            </main>
        </React.Fragment>
    )
}
const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));