import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";
import {
    Accordion,
    AccordionDetails,
    Card,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextareaAutosize
} from "@material-ui/core";
import {endpoints} from "../../constants/endpoints";
import {checkTokenAvailability, formatNumber} from "../../common/utils/utils";
import {logout} from "../../redux/actions";
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {parseTimeWithUserTimezone} from "../../common/timeHandler/timeHandler";
import {calculateTime} from "./dateHelper";
import TextField from "@material-ui/core/TextField";
import {labels} from "../../constants/inputs/labels";
import {placeholders} from "../../constants/inputs/paceholders";
import DialogContentText from "@material-ui/core/DialogContentText";
import {incidentStatus, itemStatus} from "../../constants/status/status";
import Select from "react-select";
import jwt from "jwt-decode";
import moment from "moment";
import ExportForm from '../../common/exportForm/exportForm';

export default function ViewIncidentLogs() {
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.isLogged);
    let history = useHistory();
    const [incidentLogs, setIncidentLogs] = useState([]);
    //const [validProfileLink, setValidProfileLink] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const classes = useRowStyles();
    const [exportValues, setExportValues] = useState([]);

    // const validateLinkToProfile = async (linkToProfile) => {
    //     let patientId = linkToProfile.split("/").pop();
    //     const token = JSON.parse(localStorage.getItem("key"));
    //     const patientPromise = await fetch(endpoints.getPatientData + patientId, {
    //         method: 'get',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token.accessToken
    //         },
    //     });
    //     const patientData = await patientPromise.json();
    //     if (patientData.error === 'Patient cannot be found') {
    //         setValidProfileLink(false)
    //     } else {
    //         //save the link
    //         setValidProfileLink(true);
    //     }
    // }

    function getIncidentLogs() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.viewIncidentLogs, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setIncidentLogs(response.data);
                setIsLoading(false);
                let final = [];
                response.data.map(log => {
                    let arr = [];
                    arr.push(log.incident_id);
                    arr.push(moment(log.incident_date).format('l'));
                    arr.push(log.email);
                    arr.push(log.issue_category);
                    arr.push(log.status !== null ? log.status : 'Open');
                    arr.push(log.closed_date && log.created_date ? calculateTime(log.closed_date, log.created_date)
                        : log.closed_date && log.incident_date ? calculateTime(log.closed_date, log.incident_date) : 'Issue not closed');
                    final.push(arr);
                });
                setExportValues(final);
            })
            .catch(err => console.log('error', err))
    }

    useEffect(() => {
        setIsLoading(true);
        getIncidentLogs()
    }, []);

    function generate(logs) {
        if (logs !== null) {
            return logs.map(el => {
                return <p>{el.note_description} - {el.username} {parseTimeWithUserTimezone(el.created_date)}</p>
            })
        }
        return <p></p>;
    }

    const headers = [
        { text: "Case No.", style: "tableHeader" },
        { text: "Submit Date", style: "tableHeader" },
        { text: "Submit By", style: "tableHeader" },
        { text: "Incident Category", style: "tableHeader" },
        { text: "Case Status", style: "tableHeader" },
        { text: "Resolution Time", style: "tableHeader" },
    ];
    const excelHeaders = [
        "Case No",
        "Submit Date",
        "Submit By",
        "Incident Category",
        "Case Status",
        "Resolution Time",
    ];
    function Row(props) {
        const { row } = props;
        const [openRow, setOpenRow] = useState(false);
        const [open, setOpen] = useState(false);
        const [statusValue, setStatusValue] = useState("");
        const [openContact, setOpenContact] = useState(false);
        const [openUpdate, setOpenUpdate] = useState(false);
        const [noteValue, setNoteValue] = useState(false);
        const [emailValue, setEmailValue] = useState("");
        const [validProfileLink, setValidProfileLink] = useState(true);
        const [profileLink, setProfileLink] = useState(row.link_to_patient_profile);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleClickOpenContact = () => {
            setOpenContact(true);
        };

        const handleCloseContact = () => {
            setOpenContact(false);
        };

        const handleClickOpenUpdate = () => {
            setOpenUpdate(true);
        };

        const handleCloseUpdate = () => {
            setOpenUpdate(false);
        };

        const validateLinkToProfile = async (linkToProfile, incidentId) => {
            let path = window.location.origin + "/patients/patientProfile";
            if (path === linkToProfile.slice(0, linkToProfile.lastIndexOf("/"))) {
                let patientId = linkToProfile.split("/").pop();
                const token = JSON.parse(localStorage.getItem("key"));
                const patientPromise = await fetch(endpoints.getPatientData + patientId, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                });
                const patientData = await patientPromise.json();
                if (patientData.error === 'Patient cannot be found') {
                    setValidProfileLink(false);
                } else {
                    //save the link
                    setValidProfileLink(true);
                    handlerChangeLinkToPatient(incidentId, linkToProfile);
                }
            } else {
                setValidProfileLink(false);
            }
        }
        const handlerChangeLinkToPatient = async (incidentId, linkToProfile) => {
            const token = JSON.parse(localStorage.getItem("key"));
            await fetch(endpoints.updateIncidentLinkToPatient, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    "incidentId": incidentId,
                    "linkToProfile": linkToProfile,
                })
            });
        }
        const handlerChangeItemStatus = async (incidentId, statusValue) => {
            const token = JSON.parse(localStorage.getItem("key"));
            const closedDate = statusValue[0].value === "Closed" ? moment(new Date()) : null;
            await fetch(endpoints.updateStatusIncident, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    "incidentId": incidentId,
                    "status": statusValue[0].value,
                    "closedDate":  closedDate,
                })
            });
            handleCloseUpdate();
            history.push('/refresh/incident/logs');
        }
        const handlerAddNote = async (incidentId, noteValue) => {
            const token = JSON.parse(localStorage.getItem("key"));
            const userId = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload.user_id;
            await fetch(endpoints.addNoteIncident, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    "incidentId": incidentId,
                    "note": noteValue,
                    "user_id": userId
                })
            });
            handleClose();
            history.push('/refresh/incident/logs');
        }
        const handleContactSubmitterEmail = async (incident, emailContent) => {
            const token = JSON.parse(localStorage.getItem("key"));
            await fetch(endpoints.contactIncidentSubmitter, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    user_email: incident.email,
                    user_phone: incident.phone,
                    user_organization: incident.dme_name !== null ? incident.dme_name : incident.hospice_name,
                    incident_date: incident.incident_date,
                    patient_mr: incident.patient_mr,
                    incident_category: incident.issue_category,
                    incident_category_other: incident.issue_other_category,
                    incident_description: incident.issue_brief_description,
                    incident_id: incident.incident_id,
                    email_content: emailContent
                })
            });
            handleCloseContact();
        }
        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell key={row.incident_id}>
                        <IconButton aria-label="expand row" size="small" onClick={(e) => {
                            e.preventDefault();
                            setOpenRow(!openRow);
                        }}>
                            {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{row.incident_id}</TableCell>
                    <TableCell align="left">{row.created_date ? parseTimeWithUserTimezone(row.created_date) : parseTimeWithUserTimezone(row.incident_date)}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.issue_category}</TableCell>
                    <TableCell align="left">{row.status !== null ? row.status : 'Open'}</TableCell>
                    <TableCell align="left">{row.closed_date && row.created_date ? calculateTime(row.closed_date, row.created_date)
                        : row.closed_date && row.incident_date ? calculateTime(row.closed_date, row.incident_date) : 'Issue not closed'}
                    </TableCell>
                    <TableCell align="left">
                            <TextField
                                fullWidth
                                variant={"outlined"}
                                size={"small"}
                                label={labels.patientProfileLink}
                                name={"patientProfileLink"}
                                value={profileLink}
                                style={{width: "260px"}}
                                placeholder={placeholders.linkToProfile}
                                onChange={(event) => {
                                    setProfileLink(event.target.value);
                                }}
                                onBlur={(event) => {
                                    if(event.target.value.trim() !== '') {
                                        validateLinkToProfile(event.target.value, row.incident_id);
                                    } else {
                                        handlerChangeLinkToPatient(row.incident_id);
                                        setValidProfileLink(true);
                                    }
                                }}
                                error={!validProfileLink}
                                helperText={!validProfileLink ? 'Please enter a valid profile link' : ' '}
                            />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={openRow} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                            <Box margin={1}>
                                <Grid container xs={12} spacing={3} >
                                    <Grid item xs={6}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Issue
                                        </Typography>
                                        <Card className={classes.rootBox}>
                                            <CardContent>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>User email:</strong> {row.email}
                                                </Typography>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>User phone:</strong> {row.phone}
                                                </Typography>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>Organization:</strong> {row.hospice_name !== null ? row.hospice_name : row.dme_name }
                                                </Typography>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>Incident Date:</strong> {row.created_date ? parseTimeWithUserTimezone(row.created_date) : parseTimeWithUserTimezone(row.incident_date)}
                                                </Typography>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>Incident Category:</strong> {row.issue_category}
                                                </Typography>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>Incident Category Other:</strong> {row.issue_other_category}
                                                </Typography>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>Incident Description:</strong> {row.issue_brief_description}
                                                </Typography>
                                                <Typography className={classes.title} color="primary" gutterBottom>
                                                    <strong>Patient MR#:</strong> {row.patient_mr}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            className={classes.buttonSpace}
                                            onClick={() => {
                                                handleClickOpen()
                                            }}
                                        >Log Investigation Notes</Button>
                                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Add Log Investigation Note</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Please describe the investigation log note
                                                </DialogContentText>
                                                <TextareaAutosize
                                                    className={classes.textBoxNote}
                                                    aria-label="maximum height"
                                                    placeholder="Add note description, max text length 2000 characters"
                                                    maxLength={2000}
                                                    onChange={(e)=> {
                                                        setNoteValue(e.target.value)
                                                    }}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose} color="primary" variant={'contained'}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={() => {handlerAddNote(row.incident_id, noteValue)}} color="primary" variant={'contained'}>
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            className={classes.buttonSpace}
                                            onClick={() => {
                                                handleClickOpenUpdate()
                                            }}
                                        >Update Case Status</Button>
                                        <Dialog open={openUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Update Case Incident Status</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Please choose a status for incident
                                                </DialogContentText>
                                                <div className={classes.selectBox} style={{zIndex: 300}}>
                                                    <Select
                                                        options={incidentStatus}
                                                        value={statusValue}
                                                        onChange={(e) => {
                                                            setStatusValue([{label:e.label, value:e.value}])}
                                                        }
                                                    />
                                                </div>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseUpdate} color="primary" variant={'contained'}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={() => {handlerChangeItemStatus(row.incident_id, statusValue)}} color="primary" variant={'contained'}>
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            className={classes.buttonSpace}
                                            onClick={() => {
                                                handleClickOpenContact()
                                            }}
                                        >Contact Submitter</Button>
                                        <Dialog open={openContact} onClose={handleCloseContact} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Contact Submitter by phone or email</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Please use phone or email to contact submitter
                                                </DialogContentText>
                                                <Typography align="left">
                                                    {<a href={`tel:${row?.phone}`}
                                                        className={classes.link}>{formatNumber(row?.phone)}</a>}
                                                </Typography>
                                                <TextareaAutosize
                                                    className={classes.textBoxNote}
                                                    aria-label="maximum height"
                                                    placeholder="Add email content, max text length 2000 characters"
                                                    maxLength={2000}
                                                    onChange={(e) => {
                                                        setEmailValue(e.target.value)
                                                    }}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseContact} color="primary" variant={'contained'}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={() => {
                                                    handleContactSubmitterEmail(row, emailValue);
                                                }} color="primary" variant={'contained'}>
                                                    Send email
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Accordion>
                                            <AccordionSummary className={classes.accStyle}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Investigation Notes</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div>
                                                    {generate(row.logs_incident)}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <div>
            {(isLogged) ? <React.Fragment>
                <Grid container justify={"flex-end"}>
                    <ExportForm
                        showPrint={true}
                        headers={headers}
                        fileName={"Incident Logs Export"}
                        excelHeaders={excelHeaders}
                        exportValues={exportValues}
                    />
                </Grid>
                <Grid container justify='space-between' >
                    <Grid item>
                        {checkPermissions([permissions.create_inventory]) &&
                        <Grid container spacing={1} justify={"space-between"}>
                            <TableContainer className={classes.container} component={Paper}>
                                <Table aria-label = "collapsible table sticky table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="left">Case No.</TableCell>
                                            <TableCell align="left">Submit Date</TableCell>
                                            <TableCell align="left">Submit By</TableCell>
                                            <TableCell align="left">Incident Category</TableCell>
                                            <TableCell align="left">Case Status</TableCell>
                                            <TableCell align="left">Time To Resolution</TableCell>
                                            <TableCell align="left">Link To Profile</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {incidentLogs.map((row) => (
                                            <Row key={row.incident_id} row={row} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </React.Fragment> : ''}
        </div>
    )
}

const useRowStyles = makeStyles({
    root: {
        borderBottom: 'none',
    },
    rootBox: {
        minWidth: 275,
        marginRight: '10px',
        borderBottom: 'none',
    },
    title: {
        fontSize: 14,
    },
    rootAcc: {
        width: '100%',
    },
    buttonSpace: {
        marginLeft: '10px',
        marginBottom: '20px'
    },
    container: {
        maxHeight: 800,
        marginTop: '20px'
    },
    logsNotes: {
        padding:8,
        marginBottom:8,
        border:'1px solid lightgrey',
        borderRadius:4
    },
    column: {
        display: 'inline-grid'
    },
    accStyle: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    collapseWrapper: {
        width: '100%'
    },
    textBoxNote: {
        minHeight: '200px',
        minWidth: '300px'
    },
    selectBox: {
        height: 300
    },
    link: {
        color: "blue",
        textDecoration: "underline",
    }
});
