import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../../redux/actions';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {endpoints} from "../../constants/endpoints";
import {routes} from "../../constants/routes/routes";
import jwt from 'jwt-decode'
import {notifySnack} from "../../common/notifications/apiStatusNotification";
import {useSnackbar} from "notistack";
import {snackbarMessages} from "../../constants/alert/alertMessages";
import Grid from "@material-ui/core/Grid";
import {Hidden} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Logo from "../../assets/logo_caption_full@2x.png";
import SimpleLogo from "../../assets/logo_caption@2x.png";
import PoweredLogo from "../../assets/powered_by_capstone@2x.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {loginStyle} from "./log-in.style";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {redirectAfterLogin} from "../../common/utils/utils";


export default function LogIn(props) {
    const classes = useStyles();
    let tempToken = (new URLSearchParams(window.location.search)).get("token");
    props.setShowNav(false);
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();
    const location = useLocation();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [showAlert,setShowAlert] = useState(false);
    const [showDialog,setShowDialog] = useState(false);
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.isLogged);
    const [redirectLocation, setRedirectLocation] = useState({});

    //disabled/deleted user state
    const [formLoading,setFormLoading] = useState(false);
    const [formUsername,setFormUsername] = useState("");
    const [formEmail,setFormEmail] = useState("");
    const [formOrganization,setFormOrganization] = useState("");
    const [formMessage,setFormMessage] = useState("");
    const [formEmailEmpty,setFormEmailEmpty] = useState(false);
    const [formEmailInvalid,setFormEmailInvalid] = useState(false);

    const [expirationDateToken, setExpirationToken] = useState();

    const handleClose = () => {
        setShowDialog(false);
        resetContactForm();
    };

    useEffect(() => {
        if (tempToken !== null) {
            const userTempToken = jwt(tempToken);
            props.setLoginToken(userTempToken);
                setExpirationToken(userTempToken)
        }
    }, [tempToken]);

    useEffect(() => {
        if(location.state && Object.keys(location.state).length > 0) {
            setRedirectLocation(location.state);
        }
    }, [location])

    const onSubmitForm = (e) => {
        setLoading(true);
        setDisabledButton(true);
        let user = {
            username: username && username.toString().trim(),
            password: password && password.toString().trim(),
        };
        fetch(`${endpoints.login}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(user)
            }
        )
            .then(response => {
                if (response.status === 200) {
                } else if (response.status === 403) {
                    setShowAlert(true);
                    // setTimeout(() => history.push("/support"), 1000);
                } else {
                    notifySnack(enqueueSnackbar, response.status, snackbarMessages.invalidUsernamePassword, true);
                }
                setDisabledButton(false);
                setLoading(false);
                return response.json();
            }).then(response => {
            const user = jwt(response.accessToken);
            dispatch(login(user.payload.username));
            localStorage.setItem('key', JSON.stringify(response));
            props.setShowNav(true);
            if(!user.payload.temporary_password){
                redirectAfterLogin(history, redirectLocation);
            }
            if (user.payload.temporary_password) {
                history.push({
                    pathname: `${routes.password_change}`,
                    temporary_password: true
                })
            }
        })
            .catch(err => {
                setDisabledButton(false);
            });
    };

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const resetContactForm = () => {
        setFormUsername("");
        setFormEmail("");
        setFormOrganization("");
        setFormMessage("");
        setFormEmailInvalid(false);
        setFormEmailEmpty(false);
    }

    const submitContactForm = (e) => {
        e.preventDefault();
        const data = {
            email: formEmail,
            username: formUsername,
            organization: formOrganization,
            message: formMessage
        }

        if(formEmail.trim() === ''){
            setFormEmailInvalid(false);
            setFormEmailEmpty(true);
        }
        else if(validateEmail(formEmail) === false){
            setFormEmailEmpty(false);
            setFormEmailInvalid(true);
        }
        else {
            setFormLoading(true);
            fetch(`${endpoints.sendDeletedOrDeactivatedUserEmail}`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({data})
                }
            )
                .then(response => {
                    setFormLoading(false);
                    setShowDialog(false);
                    resetContactForm();
                    notifySnack(enqueueSnackbar, response.status, snackbarMessages.deactivatedOrDeletedUser, true);
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }

    return (
        <React.Fragment>
            {(props.loginToken.expiration && props.loginToken.expiration !== '' && props.loginToken.expiration.length !==0 && moment(props.loginToken.expiration).toDate() < new Date() ) ? (
                <Typography
                    variant={"h5"}
                    className={classes.text}
                >
                    LINK is expired.
                </Typography>
            ) : (
                <Grid container justify={"center"} alignContent={"center"} style={{minHeight:'100vh'}}>
                    {(!isLogged) ? (
                        <React.Fragment>
                            <Hidden mdUp>
                                <Grid container
                                      className={classes.container}
                                      style={{display:'flex', alignContent:"space-between", alignItems:"space-between"}}
                                >
                                    <Grid item xs={12}>
                                            <img
                                                src={SimpleLogo}
                                                alt="logo"
                                                className={classes.simpleLogo}
                                            />
                                    </Grid>
                                    <Grid item className={classes.contentWrapper}>
                                        <Grid item
                                            className={classes.contentGrid}
                                            >
                                        <Typography
                                            variant={"h2"}
                                            className={classes.h2}
                                        >
                                            LOG IN
                                        </Typography>
                                            {showAlert && (
                                                <Alert severity="error" style={{margin:"5px 0",textAlign: 'left'}}>
                                                    <span style={{display: 'inline'}}>You do not have permission to log in. Your account was either temporary disabled or deleted. If you think this is incorrect, please contact the support by
                                                        <span style={{fontWeight:700,cursor:"pointer"}} onClick={() => {setShowDialog(true)}}>{" "}clicking here</span>
                                                    </span>
                                                </Alert>
                                            )}
                                        <CssTextField
                                            autoFocus
                                            id={'username'}
                                            variant={'outlined'}
                                            label={'Username'}
                                            size={'small'}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {letterSpacing: 0.39,}
                                            }}
                                            inputProps={{style: {fontSize: 14}}}
                                            style={{
                                                width: '100%',
                                                marginTop: 22,
                                            }}
                                            defaultValue={username}
                                            onChange={(e) => {
                                                setUsername(e.target.value)
                                            }}
                                            onKeyUp={(event) => {
                                                if (event.key === 'Enter') {
                                                    onSubmitForm()
                                                }
                                            }}
                                        />
                                        <CssTextField
                                            id={'password'}
                                            variant={'outlined'}
                                            label={'Password'}
                                            size={'small'}
                                            type="password"
                                            value={password}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {letterSpacing: 0.39,}
                                            }}
                                            inputProps={{style: {fontSize: 14}}}
                                            style={{
                                                width: '100%',
                                                marginTop: 33,
                                            }}
                                            onChange={event => {
                                                setPassword(event.target.value)
                                            }}
                                            onKeyUp={(event) => {
                                                if (event.key === 'Enter') {
                                                    onSubmitForm()
                                                }
                                            }}
                                        />
                                        <div className={classes.buttonContainer}>
                                            <Button
                                                id='loginButton'
                                                variant='contained'
                                                color='primary'
                                                className={classes.button}
                                                onClick={() => !disabledButton && onSubmitForm()}
                                                disabled={loading}
                                                style={{width:140}}
                                            >
                                                {loading ? <CircularProgress style={{color:'white'}} size={24}/> : "LOGIN"}
                                            </Button>
                                        </div>
                                        <Typography
                                            variant={"h5"}
                                            className={classes.text}
                                            onClick={() => history.push("/forgotCredentials")}
                                        >
                                            Forgot username or password?
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img src={PoweredLogo}
                                             alt=""
                                             name='admin'
                                             className={classes.poweredLogo}
                                        />
                                    </Grid>
                                </Grid>
                            </Hidden>

                            <Hidden smDown>
                                <Grid item lg={4} md={4}
                                      className={classes.desktopGrid}
                                >
                                    <Typography
                                        variant={"h3"}
                                        className={classes.h3}
                                    >
                                        Welcome to
                                    </Typography>
                                    <img
                                        src={Logo}
                                        alt="logo"
                                        width='272'
                                    />
                                    <Typography className={classes.description}>
                                        Sign in with an existing account or request access from your admin
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12}
                                      className={classes.desktopForm}>
                                    <Typography
                                        variant={"h2"}
                                        className={classes.h2}
                                    >
                                        LOG IN
                                    </Typography>
                                    {showAlert && (
                                        <Alert severity="error" style={{margin:"5px 0",textAlign:'left'}}>
                                            <span style={{display: 'inline'}}>You do not have permission to log in. Your account was either temporary disabled or deleted. If you think this is incorrect, please contact the support by
                                                <span style={{fontWeight:700,cursor:"pointer"}} onClick={() => {setShowDialog(true)}}>{" "}clicking here</span>
                                            </span>
                                        </Alert>
                                    )}
                                    <CssTextField
                                        autoFocus
                                        id={'username'}
                                        username={'username2'}
                                        variant={'outlined'}
                                        label={'Username'}
                                        size={'small'}
                                        value={username}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {letterSpacing: 0.39,}
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        className={classes.textfield}
                                        onChange={(e) => setUsername(e.target.value)}
                                        onKeyUp={(e) => e.key === 'Enter' && onSubmitForm()}
                                    />
                                    <CssTextField
                                        id={'password'}
                                        variant={'outlined'}
                                        label={'Password'}
                                        size={'small'}
                                        value={password}
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {letterSpacing: 0.39,}
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        className={classes.password}
                                        onChange={event => setPassword(event.target.value)}
                                        onKeyUp={(e) => e.key === 'Enter' && onSubmitForm()}
                                    />
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            id='loginButton'
                                            variant='contained'
                                            color='primary'
                                            className={classes.button}
                                            onClick={() => !disabledButton && onSubmitForm()}
                                            disabled={loading}
                                            style={{width:'140px'}}
                                        >
                                            {loading ? <CircularProgress style={{color:'white'}} size={24}/> : "LOGIN"}
                                        </Button>
                                    </div>
                                    <Typography
                                        variant={"h5"}
                                        className={classes.text}
                                        onClick={() => history.push("/forgotCredentials")}
                                    >
                                        Forgot username or password?
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Dialog open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Contact support</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        To contact the support regarding the temporary deactivation / deletion of your account, please fill this form.
                                    </DialogContentText>
                                    <TextField
                                        value={formUsername}
                                        onChange={(e) => setFormUsername(e.target.value)}
                                        margin="dense"
                                        id="username"
                                        label="Username"
                                        type="text"
                                        fullWidth
                                    />
                                    <TextField
                                        value={formEmail}
                                        onChange={(e) => setFormEmail(e.target.value)}
                                        margin="dense"
                                        id="email"
                                        label="Email Address *"
                                        type="email"
                                        fullWidth
                                    />
                                    {formEmailEmpty && (
                                        <Typography style={{fontSize:'14px', color: 'red', fontWeight:600}}>Email address can not be empty!</Typography>
                                    )}
                                    {formEmailInvalid && (
                                        <Typography style={{fontSize:'14px', color: 'red', fontWeight:600}}>You must provide a valid email!</Typography>
                                    )}
                                    <TextField
                                        value={formOrganization}
                                        onChange={(e) => setFormOrganization(e.target.value)}
                                        margin="dense"
                                        id="name"
                                        label="Organization"
                                        type="text"
                                        fullWidth
                                    />
                                    <TextField
                                        value={formMessage}
                                        onChange={(e) => setFormMessage(e.target.value)}
                                        margin="dense"
                                        id="message"
                                        label="Message"
                                        type="text"
                                        placeholder={"Write down a message..."}
                                        rows={3}
                                        fullWidth
                                    />
                                </DialogContent>
                                <DialogActions style={{marginTop: '15px',marginRight: '15px'}}>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={submitContactForm} color="primary" variant='contained'>
                                        {formLoading ? <CircularProgress style={{color:'white'}} size={24}/> : "Send"}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    ) :
                        <Redirect to={{
                            pathname: "/dashboard",
                            state: { showNotification: true }
                        }}/>
                    }
                </Grid>
            )}
        </React.Fragment>
    );
}
const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => (loginStyle));
