import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {endpoints} from "../../../constants/endpoints";
import ExportForm from "../../../common/exportForm/exportForm";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useHistory} from "react-router-dom";
import {Table} from "../../../common/table/table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import ReportsHeader from "../../../common/reportsHeader/reportsHeader";

export const LostItemsReport = props => {
    const isLogged = useSelector(state => state.isLogged);
    const classes = useStyles();
    const token = JSON.parse(localStorage.getItem('key'));
    const [results, setResults] = useState([]);
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const generateReport = (payload) => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        fetch(endpoints.getLostItems, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let arr = [];
                response.data.map(item => {
                    item.lost_date= moment(item.lost_date).format('LL')
                    arr.push(item);
                });
                setResults(arr);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
            })
    };
    const clearData = () => {
        setResults([]);
    };
    useEffect(() => {
        if (results !== undefined) {
            let final = [];
            results.map(value => {
                let newArr = [];
                newArr.push(value.hospice_name);
                newArr.push(value.region);
                newArr.push(value.item_id);
                newArr.push(value.item_name ? value.item_name : value.name);
                newArr.push(value.item_status ? value.item_status : '-');
                newArr.push(value.facility_name ? value.facility_name : '-');
                newArr.push(new Date(moment(value.lost_date).format('LL')));
                newArr.push(value.patient_id);
                newArr.push(value.medical_record);
                newArr.push(value.first_name);
                newArr.push(value.last_name);
                newArr.push(value.address1 + " " + value.address2 + "," + value.city + " " +  value.state + " " +  value.zip);
                newArr.push(value.comment);
                newArr.push(value.recovery_date ? new Date(value.recovery_date) : "-");
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [results]);
    const headers = [
        {text: "Hospice_Name", style: 'tableHeader'},
        {text: "Region", style: 'tableHeader'},
        {text: "Item_ID", style: 'tableHeader'},
        {text: "Item_Name", style: 'tableHeader'},
        {text: "Status", style: 'tableHeader'},
        {text: "Facility_Name", style: 'tableHeader'},
        {text: "Lost_Date", style: 'tableHeader'},
        {text: "Patient_ID", style: 'tableHeader'},
        {text: "Medical_Record", style: 'tableHeader'},
        {text: "Patient First Name", style: 'tableHeader'},
        {text: "Patient Last Name", style: 'tableHeader'},
        {text: "Patient_Address", style: 'tableHeader'},
        {text: "Comment", style: 'tableHeader'},
        {text: "Recovery_Date", style: 'tableHeader'},
    ];
    const excelHeaders = [
       "Hospice_Name",
       "Region",
       "Item_ID",
       "Item_Name",
       "Status",
       "Facility_Name",
       "Lost_Date",
       "Patient_ID",
       "Medical_Record",
       "Patient_First_Name",
       "Patient_Last_Name",
       "Patient_Address",
       "Comment",
       "Recovery_Date",
    ];
    return (
        <div className={classes.div}>
            {(isLogged) &&
            <Grid container className={classes.container}>
                <Typography className={classes.maintitle} style={{fontWeight:'bold', fontSize:16}}>Lost Items Report</Typography>
                <ReportsHeader generateReport={generateReport} clearData={clearData}/>
                <Grid item container xs={12}>
                    <Grid item xs={12} className={classes.flex}>
                        <ExportForm
                            fileName='Lost Item Report'
                            exportValues={exportValues}
                            headers={headers}
                            excelHeaders={excelHeaders}
                            showPrint={true}
                            landscape={true}
                        />
                    </Grid>
                    <Grid item xs={12} style={{overflow:'hidden', maxWidth:'calc(100vw - 24px)'}}>
                        <Table
                            showSearch={true}
                            search={true}
                            filtering={true}
                            columns={[
                                {title: 'Hospice Name', field: 'hospice_name'},
                                {title: "Region", field: 'region'},
                                {title: "Item ID", field: 'item_id'},
                                {title: "Item Name", field: 'item_name'},
                                {title: "Status", field: 'item_status'},
                                {title: "Facility Name", field: 'facility_name'},
                                {title: "Lost Date", field: 'lost_date'},
                                {title: 'Patient ID', field: 'patient_id'},
                                {title: 'Medical Record', field: 'medical_record'},
                                {title: 'First Name', field: 'first_name'},
                                {title: 'Last Name', field: 'last_name'},
                                {title: 'Patient Address', field: 'patient_id',
                                    render: rowData => <div>{rowData.address1 + " " + rowData.address2 + "," + rowData.city + " " +  rowData.state + " " +  rowData.zip}</div>},
                                {title: 'Comment', field: 'comment'},
                                {title: "Recovery Date", field: 'recovery_date'}
                            ]}
                            items={results}
                            backGroundColor={"rgba(237, 242, 244, 0.73)"}
                            textColor={"rgba(46, 50, 54, 1)"}
                        />
                    </Grid>

                </Grid>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
            }
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    div: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        padding: 12,
        // maxWidth: 1200,
    },
    title: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 200
    },
    maintitle: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 220
    },
    select: {
        padding: 12,
        display: "flex",
        flexDirection: "column",
        justifyContent: "left"

    },
    org: {
        border: '1px solid lightgrey',
        borderRadius: 4,
        marginRight: 12,
        // height: 40,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: "9px 0px",
    },
    region: {
        border: '1px solid lightgrey',
        height: 40,
        borderRadius: 4,
        marginBottom: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    flex: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 20,
        marginBottom: 12
    }
}));
