import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import {endpoints} from "../../../../constants/endpoints";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {maxInputLength} from "../../../../constants/inputs/values";


export default function CreateSystemNotifications() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [type, setType] = useState([{
        label:'Informational', value:"Informational"
    }]);
    const [colors, setColors] = useState({
        backgroundColor:'',
        borderColor:""
    });
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [dismissible, setDismissible] = useState(true);
    const [active, setActive] = useState(true);
    const [name, setName] = useState('');
    const [start_date, setStart_date] = useState(null);
    const [end_date, setEnd_date] = useState(null);

    const [isValid, setIsValid] = useState(false);
    const [notificationId, setNotificationId] = useState(null);

    const createOrUpdateNotification = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.createOrUpdateSystemNotification}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            },
            body: JSON.stringify({
                type: type[0].label,
                name: name,
                start_date: start_date,
                end_date: end_date, // TODO: set end of day for end date to fix alert not appearing on the end date day
                title: title,
                message: message,
                dismissible: dismissible,
                active: active,
                history_note:"Notification Created: Lorin Muller created notification on 05 Feb 2021", // TODO- Remove Lorin
                notificationId:notificationId
            })
        })
        .then(response => {
            if (response.status === 201) {
                enqueueSnackbar("System notification created with success.", {
                    persist: false,
                    variant: "success",
                });

            } else {
                console.log('error')
            }
            history.push('/admin/system/notifications/send');
            return response.json();
        })
        .then(response => {

        })
            .catch(err => {

            })
    };

    useEffect(()=> {
        if (type[0].value === "Informational"){
            setColors({
                backgroundColor:'#E4EBF1',
                borderColor:"#7B99B5"
            })
        } else if (type[0].value === "Warning"){
            setColors({
                backgroundColor:'#F6F4DF',
                borderColor:"#CEC129"
            });
        } else if (type[0].value === "Important"){
            setColors({
                backgroundColor:'#F5B8BF',
                borderColor:"#CD1D32"
            });
        }
    },[type]);


    useEffect(()=> {
       if(name !== "" && message !== "") {
           setIsValid(true)
       } else {
           setIsValid(false)
       }
    }, [name, message]);



    return (
        <Grid container style={{padding:24}}>
            <Grid container style={{padding:24, backgroundColor:'white', borderRadius:4}} spacing={2}>
                <Grid item sm={6} xs={12} style={{display:'flex', alignContent:'flex-end', alignItems:"flex-end"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{display:'flex', padding:12}}>
                            <Typography
                                style={{
                                    fontWeight:'bold', fontSize:24
                                }}
                            >
                                Create New Notification
                            </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12} style={{textAlign:'left', zIndex:20}}>
                            <div style={{color:'grey'}}>Notification Type *</div>
                            <Select
                                options={
                                    [
                                        {value:'Informational', label:'Informational'},
                                        {value:'Warning', label:'Warning'},
                                        {value:'Important', label:'Important'}
                                    ]
                                }
                                placeholder={'Select Type'}
                                onChange={(e)=> setType([{
                                    label:e.value,
                                    value:e.value,
                                }])}
                                value={type}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12} style={{display:'flex', alignContent:'flex-end', alignItems:"flex-end"}}>
                            <div style={{width:'100%'}}>
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    size={'small'}
                                    label={'Name'}
                                    required
                                    value={name}
                                    onChange={(e)=> setName(e.target.value)}
                                    inputProps={{maxLength: maxInputLength.L255}}
                                    helperText={name && `${name.length < maxInputLength.L255 ? name.length.toString() + `/${maxInputLength.L255}`: name.length.toString() + `/${maxInputLength.L255} You have reached the character limit for a notification name.` }`}
                                    error={name && name.length === maxInputLength.L255}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={6} xs={12} style={{display:'flex', alignContent:'flex-end', alignItems:"flex-end"}}>
                    <Grid container>


                        <Grid item xs={12} style={{display:'flex'}}>
                            <div
                                style={{width:'100%'}}
                            >
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    size={'small'}
                                    label={'Title'}
                                    value={title}
                                    onChange={(e)=> setTitle(e.target.value)}
                                    inputProps={{maxLength: maxInputLength.L500}}
                                    helperText={title && `${title.length < maxInputLength.L500 ? title.length.toString() + `/${maxInputLength.L500}`: title.length.toString() + `/${maxInputLength.L500} You have reached the character limit for a notification title.` }`}
                                    error={title && title.length === maxInputLength.L500}
                                />
                            </div>
                        </Grid>



                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Grid container>


                        <Grid item xs={12} style={{display:'flex'}}>
                            <div
                                style={{width:'100%'}}
                            >
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    size={'small'}
                                    label={'Message'}
                                    required
                                    multiline
                                    rows={7}
                                    value={message}
                                    onChange={(e)=> setMessage(e.target.value)}
                                    inputProps={{maxLength: maxInputLength.L1000}}
                                    helperText={message && `${message.length < maxInputLength.L1000 ? message.length.toString() + `/${maxInputLength.L1000}`: message.length.toString() + `/${maxInputLength.L1000} You have reached the character limit for a notification message.` }`}
                                    error={message && message.length === maxInputLength.L1000}
                                />
                            </div>
                        </Grid>



                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item md={4} sm={6} xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        margin="normal"
                                        inputVariant="outlined"
                                        placeholder='MM/DD/YYYY'
                                        size={"small"}
                                        label="Start Date *"
                                        format="MM/dd/yyyy"
                                        value={start_date}
                                        onChange={(e, value) => {
                                            setStart_date(value);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{
                                            backgroundColor:'white',
                                            width:'100%',
                                            margin:0
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        margin="normal"
                                        style={{
                                            backgroundColor:'white',
                                            width:'100%',
                                            margin:0,
                                        }}
                                        inputVariant="outlined"
                                        placeholder='MM/DD/YYYY'
                                        size={"small"}
                                        label="End Date *"
                                        format="MM/dd/yyyy"
                                        value={end_date}
                                        onChange={(e, value) => {
                                            setEnd_date(value);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Grid item style={{display:'flex'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dismissible}
                                            onChange={(e)=> {
                                                setDismissible( e.target.checked)
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Is dismissible"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={active}
                                            onChange={(e)=> {
                                                setActive( e.target.checked)
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Is active"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end', paddingTop:12, paddingBottom:24}}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        disabled={!isValid}
                        onClick={createOrUpdateNotification}
                    >
                        Create Notification
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} style={{display:'flex', fontSize:16, fontWeight:'bold'}}>
                            Preview:
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', fontSize:16, fontWeight:'bold'}}>
                            <Grid container style={{borderRadius:4,
                                maxWidth:600,
                                backgroundColor: colors.backgroundColor,
                                border:`2px solid ${colors.borderColor}`,
                                paddingTop:24, paddingLeft:24, paddingRight:12,paddingBottom:12, textAlign:"left"}}>
                                <Grid item xs={12} style={{fontSize:24, paddingBottom:12}}>
                                    {title}
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:24}}>
                                    <Typography
                                        variant="body1"
                                        style={{whiteSpace: 'pre-line'}}
                                    >
                                        {message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify={dismissible === true ? "space-between" : 'flex-end'}>

                                        <Grid item style={{paddingRight:12}}>
                                            {dismissible === true ? (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Do not show anymore"
                                                />

                                            ):(
                                                <Button variant={"outlined"} color={'primary'}>I Have Read</Button>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"contained"} color={'primary'} >
                                                {dismissible === true ? "Acknowledge" : "Read Later"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
