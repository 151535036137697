import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Backdrop} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExportForm from "../../../common/exportForm/exportForm";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {endpoints} from "../../../constants/endpoints";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import jwt from "jwt-decode";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import {useSnackbar} from "notistack";
import {Table} from "../../../common/table/table";
import {makeStyles} from "@material-ui/core/styles";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};


export const TechnicianActivity = ({}) => {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();
    const [userType, setUserType] = useState(null);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [list, setList] = useState([]);
    const [technicianList, setTechnicianList] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedTechnician, setSelectedTechnician] = useState(null);
    const tableRef = React.createRef();
    const classes = useStyles();

    function getData() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        const {payload} = jwt(token.accessToken);
        fetch(endpoints.technicianReport, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                dme_id: Number(payload.dme_organization_id),
                start_date: `${startDate} 00:00:00.000`,
                end_date: `${endDate} 23:59:59.999`,
                technician_date: selectedTechnician.label,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setData(response.data);

            })
            .catch(err => {
                console.log(err, 'error');
            })
    }

    const getTechnicians = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        const {payload} = jwt(token.accessToken);
        fetch(`${endpoints.getAllTechnicians}${payload.dme_organization_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data){
                    let arr = [];
                    response.data.map(tech => {
                        arr.push({label: `${tech.first_name} ${tech.last_name}`, value:`${tech.first_name} ${tech.last_name}`})
                    });
                    setTechnicianList(arr)
                } else {
                    setTechnicianList([])
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('key'));
        if (token && token.accessToken) {
            let user = jwt(token.accessToken);
            if (user.payload.type === "DME") {
               {
                    setUserType('DME');
                    getDmeOrganizationWarehouses();
                    getTechnicians();
                }
            }
        }
    }, []);

    async function getDmeOrganizationWarehouses() {
        const token = JSON.parse(localStorage.getItem("key"));
        let user = jwt(token.accessToken);
        const promise = await fetch(endpoints.getDmeActiveWarehouses + user.payload.dme_organization_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: 'GET',
        });
        const response = await promise.json();
        if (response.status === "success") {
            let arr = [];
            response.data.map(org => {
                arr.push({
                    label: org.name,
                    value: org.warehouse_id
                })
            });
            setList(arr);
        }
        setLoading(false)
    }

    const clearData = () => {
        setData([]);
        setSelectedOrg(null);
        setSelectedTechnician(null);
        setStartDate(moment().format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
    };

    const getExportValues = () => {
        let finalArr = [];
        tableRef.current.state.data.map((el) => {
            let newArr = [];
            newArr.push(el.technician_name);
            newArr.push(new Date(el.created_date));
            newArr.push(new Date(el.completed_date));
            newArr.push(`${el.hours}:${el.minutes ? Math.abs(el.minutes) : "00"}`);
            newArr.push(el.type);
            newArr.push(el.order_id);
            newArr.push(el.patient_name);
            newArr.push(`${el.address1} ${el.address2} ${el.city} ${el.zip} ${el.state}`);

            finalArr.push(newArr);
        })

        return finalArr;
    };

    const headers = [
        {text: "Technician", style: 'tableHeader'},
        {text: "Order Placed", style: 'tableHeader'},
        {text: "Order Completed", style: 'tableHeader'},
        {text: "Time to Complete (HH:MM)", style: 'tableHeader'},
        {text: "Action", style: 'tableHeader'},
        {text: "Order#", style: 'tableHeader'},
        {text: "Patient", style: 'tableHeader'},
        {text: "Address", style: 'tableHeader'}
    ];
    const excelHeaders = [
        "Technician",
        "Order Placed",
        "Order Completed",
        "Time to Complete (HH:MM)",
        "Action",
        "Order#",
        "Patient",
        "Address",
    ];
    const columns = [
        {title: 'Technician', field: 'technician_name'},
        {title: 'Order Placed', field: 'created_date'},
        {title: 'Order Completed', field: 'completed_date'},
        {title: 'Time to Complete (HH:MM)', field: 'completion_time',
            render: rowData => <span>
                {rowData.hours}:{rowData.minutes ? Math.abs(rowData.minutes) : "00"}
            </span>
        },
        {title: 'Action', field: 'type'},
        {title: 'Order#', field: 'order_id'},
        {title: 'Patient', field: 'patient_name'},
        {title: 'Address', field: 'patient_address',
            render: rowData => <span>
                {rowData.address1} {rowData.address2} {rowData.city} {rowData.zip} {rowData.state}
            </span>
        }
    ];

    const [loading, setLoading] = useState(false);
    return <Grid container className={classes.topContainer}>
            <Backdrop open={loading} className={classes.backdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container className={classes.header}>
                Technician Activity
            </Grid>
            {
                (userType === "Admin" || userType === "DME") && (
                    <Grid item md={2} sm={4} xs={12} className={classes.padding16}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            value={selectedOrg}
                            options={list}
                            name="color"
                            styles={selectStyles}
                            onChange={(e) => setSelectedOrg(e)}
                        />
                    </Grid>
                )
            }
            {
                (userType === "Admin" || userType === "DME") && (
                    <Grid item md={2} sm={4} xs={12} className={classes.padding16}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            value={selectedTechnician}
                            options={technicianList}
                            name="color"
                            styles={selectStyles}
                            onChange={(e) => setSelectedTechnician(e)}
                        />
                    </Grid>
                )
            }
            <Grid item className={classes.paddingL16} md={2} sm={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            margin="normal"
                            className={classes.datePicker}
                            inputVariant="outlined"
                            placeholder='MM/DD/YYYY'
                            size={"small"}
                            label="Start Date"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={(e, value) => {
                                setStartDate(moment(value).format("YYYY-MM-DD"));
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item className={classes.paddingL8} md={2} sm={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            margin="normal"
                            className={classes.datePicker}
                            inputVariant="outlined"
                            placeholder='MM/DD/YYYY'
                            size={"small"}
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={(e, value) => {
                                setEndDate(moment(value).format("YYYY-MM-DD"));
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
        <Grid item container sm={4} xs={12} justify={"flex-end"} className={classes.paddingR32}>
            <Grid item className={classes.paddingButton}>
                <Button variant={'contained'} color={'primary'} onClick={getData}
                        disabled={!startDate || !endDate || !selectedOrg || !selectedTechnician}>Generate Report</Button>
            </Grid>
            <Grid item className={classes.paddingButton}>
                <Button variant={'outlined'} color={'primary'} onClick={clearData}>Clear Filters</Button>
            </Grid>
        </Grid>
            {(userType === "Admin" || userType === "DME") && (
                <Grid container className={classes.padding24} justify={'center'}>
                    <Grid item container xs={12} justify={'flex-end'}>
                        <ExportForm
                            fileName='UserCustomAuditReport'
                            headers={headers}
                            excelHeaders={excelHeaders}
                            showPrint={true}
                            getExportValues={getExportValues}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            tableRef={tableRef}
                            search={true}
                            filtering={true}
                            showSearch={true}
                            columns={columns}
                            items={data}
                            options={{
                                search: true,
                                showTitle: false
                            }}
                            backGroundColor={"rgba(237, 242, 244, 0.73)"}
                            textColor={"rgba(46, 50, 54, 1)"}
                        />
                    </Grid>
                </Grid>
            )}
    </Grid>

}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    header: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 24,
        paddingTop: 24,
        paddingLeft: 24
    },
    topContainer: {
        margin: 32,
        padding: 24,
        backgroundColor: 'white',
        borderRadius: 4
    },
    datePicker: {
        backgroundColor: 'white',
        width: '100%'
    },
    paddingR8: {paddingRight: 8},
    paddingL8: {paddingLeft: 8},
    paddingL16: {paddingLeft: 16},
    padding16: {padding: 16},
    paddingR32: {paddingRight: 32},
    paddingT24: {paddingTop: 24},
    padding24: {padding: 24},
    paddingButton: {paddingTop: 16, paddingLeft: 8},
    textAlignLeft: {textAlign: "left"},
}));
