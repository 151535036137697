import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { utilsTokenIdentifiers } from "../../../constants/inputs/values";
import { getTokenInformation } from "../../../common/utils/utils";
import { endpointStatus } from "../../../constants/messages/endpointStatus";
import { endpoints } from "../../../constants/endpoints";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const getDMEOrganizationById = async (dmeId, objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.dme_organization + dmeId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    }
}

export const getContractedHospices = async (objSetter, dmeId) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.getHospicesByDme + dmeId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    }
};

export const getContractedItems = async (dmeId, contractedHospice, objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let arr = [];
    for (let i = 0; i < contractedHospice.length; i++) {
        const promise = await fetch(endpoints.getHospiceContractedItems, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({ dme_id: dmeId, hospice_id: contractedHospice[i]?.hospice_id, hospice_region_id: contractedHospice[i]?.regions[0]?.region_id })
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            arr.push(...response.data);
        }
    }
    objSetter(arr);
};

export const printContracts = (userData, contractData, otherItems, DMEDetails) => {
    let date = new Date();
    contractData.push(...otherItems);
    let object = {
        pi:[],
        pd:[],
        lf:[],
        oof:[],
        noType: [],
    };
    let formularyItems = [[
        {
            text: `Formulary Items`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Warehouse`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Vehicles`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Repair`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]];
    let limitedFormulary = [[
        {
            text: `Limited Formulary`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Warehouse`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Vehicles`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Repair`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]];
    let oofItems = [[
        {
            text: `Out-of-Formulary`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Warehouse`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Vehicles`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Repair`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]];
    let purchaseItems = [[
        {
            text: `Purchase Items`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Warehouse`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Vehicles`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Repair`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]];
    let others = [[
        {
            text: `Other Items`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, true, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Warehouse`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, true, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Vehicles`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, true, false, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Repair`,
            fillColor: '#eeeeee',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [false, true, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]];

    contractData.map(item => {
        let existingItem = { item_type: {} };
        existingItem.item_type.type = item.type;
        existingItem.warehouse = item.warehouse ? item.warehouse : "";
        existingItem.vehicle = item.vehicle ? item.vehicle : "";
        existingItem.repair = item.repair ? item.repair : "";
        existingItem.name = item.name ? item.name : "";
        switch (item.type) {
            case "LF":
                object.lf.push(existingItem);
                break;
            case "PD":
                object.pd.push(existingItem);
                break;
            case "PI":
                object.pi.push(existingItem);
                break;
            case "OOF":
                object.oof.push(existingItem);
                break;
            default:
                object.noType.push(existingItem);
                break;
        }
    });
    object.pd.map(item => {
        formularyItems.push(
            [
                {
                    text: `${item.name}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, true, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.warehouse}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.vehicle}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.repair}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
            ],
        )
    })
    object.pi.map(item => {
        purchaseItems.push(
            [
                {
                    text: `${item.name}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.warehouse}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.vehicle}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.repair}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
            ],
        )
    })
    object.lf.map(item => {
        limitedFormulary.push(
            [
                {
                    text: `${item.name}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.warehouse}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.vehicle}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.repair}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
            ]
        )
    })
    object.oof.map(item => {
        oofItems.push(
            [
                {
                    text: `${item.name}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.warehouse}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.vehicle}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
                {
                    text: `${item.repair}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, false, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
            ]
        )
    })
    if (object.noType.length > 0) {
        object.noType.map(item => {
            others.push(
                [
                    {
                        text: `${item.name}`,
                        fillColor: 'white',
                        border: [true, true, true, true],
                        margin: [0, 2, 0, 2],
                    },
                    {
                        text: `${item.warehouse}`,
                        fillColor: 'white',
                        border: [true, false, true, true],
                        margin: [0, 2, 0, 2],
                    },
                    {
                        text: `${item.vehicle}`,
                        fillColor: 'white',
                        border: [true, false, true, true],
                        margin: [0, 2, 0, 2],
                    },
                    {
                        text: `${item.repair}`,
                        fillColor: 'white',
                        border: [true, false, true, true],
                        margin: [0, 2, 0, 2],
                    },
                ],
            )
        })
    } else {
        for (let i = 0; i < 11; i++) {
            others.push(
                [
                    {
                        text: ``,
                        fillColor: 'white',
                        border: [true, true, true, true],
                        margin: [0, 2, 0, 2],
                    },
                    {
                        text: ``,
                        fillColor: 'white',
                        border: [true, false, true, true],
                        margin: [0, 2, 0, 2],
                    },
                    {
                        text: ``,
                        fillColor: 'white',
                        border: [true, false, true, true],
                        margin: [0, 2, 0, 2],
                    },
                    {
                        text: ``,
                        fillColor: 'white',
                        border: [true, false, true, true],
                        margin: [0, 2, 0, 2],
                    },
                ],
            )
        }
    }

    let template = {
        content: [
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return '#eeeeee';
                        }
                        return '#eeeeee';
                    },
                    vLineColor: function (i, node) {
                        return '#eeeeee';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        //return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [180,40,180],
                    body: [
                        [
                            {
                                border: [true, true, true, true],
                                stack: [
                                    {
                                        ul: [
                                            { text: `Organization: ${userData.dme_name}`, listType: 'none' },
                                            { text: `Warehouse/Region: `, listType: 'none' },
                                            { text: `${DMEDetails[0]?.regions.map(region => region.warehouses.map(i => i.name + "/" + region.name).join("\n")).join("\n")}`, listType: 'none' },
                                            { text: `Address 1: ${DMEDetails[0]?.address1}`, listType: 'none' },
                                            { text: DMEDetails[0]?.address2 !== "" ? `Address 2: ${DMEDetails[0]?.address2}`: null , listType: 'none' },
                                            { text: `${DMEDetails[0]?.city} ${DMEDetails[0]?.state} ${DMEDetails[0]?.zip}`, listType: 'none' },
                                            { text: ' ', listType: 'none' },
                                        ]
                                    }
                                ]
                            },
                            {
                                text: ``,
                            },
                            {
                                border: [true, true, true, true],
                                stack: [

                                    {
                                        ul: [
                                            { text: `Date: ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`, listType: 'none' },
                                            { text: `User: ${userData.first_name} ${userData.last_name}`, listType: 'none' },
                                            { text: ' ', listType: 'none' },
                                        ]
                                    }
                                ]
                            },
                        ]
                    ]
                },
            },
            '\n',
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [240,182],
                    body: [
                        [
                            {
                                text: `Items`,
                                color: 'white',
                                fillColor: 'gray',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [true, true, false, false],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },
                            {
                                text: `Quantities`,
                                color: 'white',
                                fillColor: 'gray',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [false, true, true, false],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },
                        ]
                    ]
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [240,60,40,40],
                    body: formularyItems,
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [240,60,40,40],
                    body: limitedFormulary,
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [240,60,40,40],
                    body: oofItems,
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [240,60,40,40],
                    body: purchaseItems,
                },
            },
            '\n',
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    keepWithHeaderRows: 1,
                    dontBreakRows: true,
                    widths: [240, 60, 40, 40],
                    heights: 16,
                    body: others,
                },
            },
        ],
        styles: {
            notesTitle: {
                fontSize: 10,
                bold: true,
                margin: [0, 50, 0, 3],
            },
            notesText: {
                fontSize: 10,
            },
            small: {
                fontSize: 10
            },
            end: {
                width: '100%',
                textAlign: 'right'
            }
        },
        defaultStyle: {
            columnGap: 20,
        },
        footer: function (currentPage, pageCount) {
            return {
                margin: 10,
                columns: [
                    {
                        fontSize: 9,
                        text: [
                            {
                                text: 'Page' + currentPage.toString() + ' of ' + pageCount,
                            }
                        ],
                        alignment: 'center',
                        margin: [0, 4, 0, 3],
                    }
                ]
            };
        },
    };
    formularyItems.length === 1 && template.content.splice(3,1);
    limitedFormulary.length === 1 && template.content.splice(4,1);
    oofItems.length === 1 && template.content.splice(5,1);
    purchaseItems.length === 1 && template.content.splice(6,1);
    pdfMake.createPdf(template).print();
};

export default function PrintInventory() {
    const userData = getTokenInformation(history, utilsTokenIdentifiers.payload);
    const history = useHistory();
    const [DMEDetails, setDMEDetails] = useState([]);
    const [contractedHospice, setContractedHospice] = useState([]);
    const [contractData, setContractData] = useState([]);
    const [otherItems, setOtherItems] = useState([]);

    useEffect(() => {
        getDMEOrganizationById(userData.dme_organization_id, setDMEDetails);
        getContractedHospices(setContractedHospice, [userData.dme_organization_id]);
    }, []);

    useEffect(() => {
        getContractedItems(userData.dme_organization_id, contractedHospice, setContractData);
    }, [contractedHospice]);

    useEffect(() => {
        if (Object.keys(contractData).length !== 0 && DMEDetails.length !== 0) {
            printContracts(userData, contractData, otherItems, DMEDetails);
            history.push('/inventory');
        }
    }, [contractData, DMEDetails]);
    return (
        <div>
            
        </div>
    );
}