import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {labels} from "../../../../constants/inputs/labels";
import {TextField} from "@material-ui/core";
import {supportStyles} from "../Support.style";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import jwt from "jwt-decode";
import Typography from "@material-ui/core/Typography";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Select from "react-select";
import {endpoints} from "../../../../constants/endpoints";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import {maxInputLength} from "../../../../constants/inputs/values";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";

export const IncidentServiceForm = () => {

    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhoneNumber] = useState("");
    const [organization, setOrganization] = useState("");
    const [incidentDate, setIncidentDate] = useState(moment().format('YYYY-MM-DD'));
    const [patientMR, setPatientMR] = useState("");
    const [incidentCategory, setIncidentCategory] = useState("");
    const [incidentCategoryOther, setIncidentCategoryOther] = useState("");
    const [incidentDescription, setIncidentDescription] = useState("");
    const token = JSON.parse(localStorage.getItem('key'));
    if(token === null){
        dispatch(logout());
        history.push('/login');
    }
    const user = jwt(token.accessToken);
    let userOrgName;
    if (user.payload.dme_name !== "") {
        userOrgName = user.payload.dme_name;
    } else {
        userOrgName = user.payload.hospice_name;
    }

    useEffect(() => {
        if(token !== null){
            const {payload} = jwt(token.accessToken);
            setName(payload.first_name + " " + payload.last_name)
            setPhoneNumber(payload.phone);
            setEmail(payload.email);
            if (payload.dme_name !== "") {
                setOrganization(payload.dme_name);
            } else {
                setOrganization(payload.hospice_name);
            }
        }
    },[])

    const submitIncidentForm = (e) => {
        e.preventDefault();
        if(
            name === "" ||
            email === "" ||
            phone === "" ||
            organization === "" ||
            incidentDate === "" ||
            patientMR === "" ||
            incidentCategory === "" ||
            (incidentCategory === "Other" && incidentCategoryOther === "") ||
            incidentDescription === ""
        ){
            return enqueueSnackbar("Please complete all fields in red.", {variant: "warning"});
        }
        const incidentData = {
            user_name: name === "" ? (user.payload.first_name + " " + user.payload.last_name) : name,
            user_email: email === "" ? user.payload.email : email,
            user_phone: phone === "" ? user.payload.phone : phone,
            user_id: user.payload.user_id,
            user_organization: organization === "" ?  userOrgName : organization,
            incidentDate: incidentDate,
            patientMR: patientMR,
            incidentCategory: incidentCategory.value,
            incidentCategoryOther: incidentCategoryOther,
            incidentDescription: incidentDescription
        };
        ///support/incident/email
        const token = JSON.parse(localStorage.getItem("key"));
        fetch(endpoints.incidentSubmitFrom, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                incidentData
            })
        }).then(response => {
            enqueueSnackbar("Form Submitted Successfully.", {variant: "success"});
            history.push("/support");
        })
    };
    const classes = useStyles();

    return (
        <Grid container direction={"column"} justify={"flex-start"} alignItems={"flex-start"} spacing={3}
              className={classes.serviceFormContainer}>
            <Grid item xs={12} style={{
                background: "white",
                borderRadius: 4,
                alignItems: 'center',
                alignContent: 'center',
                width: '95%',
                border: '1px solid #D5D9DC',
                paddingRight: 48
            }}>
                <Typography
                    style={{paddingLeft: 16, textAlign: 'left', maxWidth: 700, fontWeight: 'bold', fontSize: 16}}>
                    Please use this form for non-emergent service-related issues. If you need immediate attention,
                    please contact your DME Manager. Do no use this form to place DME orders for patients.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={"h6"} style={{
                    width: '100%',
                    paddingRight: 48,
                    fontSize: 24,
                    fontWeight: 'bold',
                    color: "#00223D"
                }}>
                    Your Information
                </Typography>
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"incident_name"}
                    label={labels.name}
                    defaultValue={user.payload.first_name + " " + user.payload.last_name}
                    value={name}
                    error={name.length === 0}
                    name={"name"}
                    variant={"outlined"}
                    onChange={(e) => setName(e.target.value)}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"incident_email"}
                    name={"email"}
                    label={labels.email}
                    defaultValue={user.payload.email}
                    value={email}
                    error={email.length === 0}
                    variant={"outlined"}
                    onChange={(e) => setEmail(e.target.value)}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"incident_phone"}
                    name={"phone"}
                    label={labels.phone}
                    defaultValue={user.payload.phone}
                    value={phone}
                    error={phone.length === 0}
                    variant={"outlined"}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"incident_organization"}
                    name={"organization"}
                    label={labels.organization}
                    defaultValue={userOrgName}
                    value={organization}
                    error={organization.length === 0}
                    variant={"outlined"}
                    onChange={(e) => setOrganization(e.target.value)}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item>
                <Typography variant={"h6"} style={{
                    width: '100%',
                    paddingRight: 48,
                    fontSize: 24,
                    fontWeight: 'bold',
                    color: "#00223D"
                }}>
                    Incident Details
                </Typography>
            </Grid>
            <Grid item xs={6} style={{width: '100%', paddingRight: 48}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            margin="normal"
                            inputVariant="outlined"
                            placeholder='MM/DD/YYYY'
                            size={"small"}
                            label="Incident Date *"
                            format="MM/dd/yyyy"
                            value={incidentDate}
                            onChange={(e) => {
                                setIncidentDate(moment(e).format('YYYY-MM-DD'));
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{
                                backgroundColor: 'white',
                                width: '100%',
                                margin: 0,
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"patient_mr"}
                    label={labels.patientMR}
                    defaultValue={""}
                    name={"patient_mr"}
                    variant={"outlined"}
                    onChange={(e) => setPatientMR(e.target.value.toString())}
                    value={patientMR}
                    error={patientMR.length === 0}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48, zIndex: 100}}>
                <Select
                    isCearable
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            border: (incidentCategory === "" || incidentCategory == null) ? '1px solid red' :'1px solid lightgray', // default border color
                        }),
                    }}
                    placeholder='Incident Category *'
                    options={[
                        {label: 'Cleanliness of Equipment', value: 'Cleanliness of Equipment'},
                        {label: 'Equipment in Disrepair', value: 'Equipment in Disrepair'},
                        {label: 'Unsatisfactory Service', value: 'Unsatisfactory Service'},
                        {label: 'Other', value: 'Other'},
                    ]}
                    onChange={(e) => {
                        setIncidentCategory(e);
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48}}>
                {incidentCategory?.value === "Other" && <CssTextField
                    id={"other"}
                    label={labels.other}
                    defaultValue={""}
                    name={"other"}
                    variant={"outlined"}
                    onChange={(e) => setIncidentCategoryOther(e.target.value)}
                    size='small'
                    value={incidentCategoryOther}
                    error={incidentCategoryOther.length === 0}
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
                }
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"incident_issue_description"}
                    name={"issue"}
                    label={labels.issueDescription}
                    defaultValue={""}
                    value={incidentDescription}
                    error={incidentDescription.length === 0 || incidentDescription.length === maxInputLength.L2000}
                    variant={"outlined"}
                    multiline
                    rows={7}
                    onChange={(e) => setIncidentDescription(e.target.value)}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={
                        {
                            style: {fontSize: 14},
                            maxLength: maxInputLength.L2000
                        }
                    }
                    helperText={incidentDescription && `${incidentDescription.length < maxInputLength.L2000 ? incidentDescription.length.toString() + `/${maxInputLength.L2000}`: incidentDescription.length.toString() + `/${maxInputLength.L2000} You have reached the character limit for one note` }`}
                />
            </Grid>
            <Grid item>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={submitIncidentForm}
                    style={{marginBottom: 67}}
                >
                    {buttonText.submit}
                </Button>
            </Grid>
        </Grid>
    )
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => (supportStyles(theme)));